import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import OneCoursePb from '../ones/OneCoursePb';
import OneCourseContentPb from '../ones/OneCourseContentPb';
import CreateCourse from '../views/CreateCourse';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    await appContext.getMyPublisherCourses();
    setReady(true);
  }



  async function getData() {
    await callApi("get-course-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError("Could not fetch data, check your connection");
      }
    })
  }

  async function setRequiredCourse() {
    if (selectedCourseId) {
      setLoading(true);
      await callApi("set-required-course", { requiredCourseId: selectedCourseId, courseId: id }).then(response => {
        if (response.status === 1) {
          getData();
          appContext.tellMessage("successful");
          setSelectedCourseId("");
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError("Invalid course selected");
    }
  }

  async function publish() {
    setLoading(true);
    await callApi("publish-course", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getMyPublisherCourses();
      } else {
        appContext.tellError("Could not publish, check your connection");
      }
    })
    setLoading(false);
  }

  async function unpublish() {
    setLoading(true)
    await callApi("unpublish-course", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getMyPublisherCourses();
      } else {
        appContext.tellError("Could not unpublish, check your connection");
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              {data.title}
              <span
                className="font-bold text-muted"
                style={{
                  fontSize: "18px",
                  display: "block",
                }}
              >
                {data.numberOfParts} Part(s)
              </span>
            </h6>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Actions</h6>
                <hr />

                <button
                  className="btn btn-outline-dark m-2"
                  onClick={() => {
                    appContext.navTo({
                      item: 'view',
                      subItem: 'add-course-content',
                      extraItem: data.id
                    })
                  }}
                >
                  Add Content
                </button>

                {
                  (data.status === 'unpublished') ?
                    <button
                      className="btn btn-outline-dark m-2"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "This content will be published, continue?",
                          onConfirm: publish,
                        })
                      }}
                    >
                      Publish
                    </button>
                    : ""
                }

                {
                  (data.status === 'published') ?
                    <button
                      className="btn btn-outline-danger m-2"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "This content will be unpublished, continue?",
                          onConfirm: unpublish,
                        })
                      }}
                    >
                      Unpublish
                    </button>
                    : ""
                }

              </div>

            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Required course before taking this course</h6>
                <hr />

                {
                  (data.requiredCourseData) ?
                    <OneCoursePb data={data.requiredCourseData} />
                    :
                    <h6
                      style={{
                        fontSize: "14px",
                      }}
                      className=""
                    >
                      No course is required
                    </h6>
                }

                <div className="mb-2 mt-4">
                  <label className="form-label">Set required course</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setSelectedCourseId(e.target.value)
                    }}
                    value={selectedCourseId}
                  >
                    <option value=""></option>
                    {
                      (appContext.myPublisherCourses && appContext.myPublisherCourses.length > 0) ?
                        appContext.myPublisherCourses.map((item, i) => {
                          if (item.supportCertificates === 'yes' && Number(item.id) !== Number(data.id)) {
                            return (
                              <option key={i} value={item.id}>{item.title}</option>
                            )
                          }
                        })
                        : ""
                    }
                  </select>
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-secondary btn-rounded"
                    onClick={() => {
                      appContext.activateDialog({
                        message: "The selected course will be required to take before users take this course",
                        onConfirm: setRequiredCourse
                      })
                    }}
                  >
                    Set Course
                  </button>
                </div>



              </div>

            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Course Contents</h6>
                <hr />

                {
                  (data.contentData && data.contentData.length > 0) ?
                    data.contentData.map((item, i) => {
                      return (
                        <OneCourseContentPb key={i} data={item} />
                      )
                    })
                    :
                    <h6
                      style={{
                        fontSize: "14px",
                      }}
                      className=""
                    >
                      No course is required
                    </h6>
                }



              </div>

            </div>
          </div>

          <div className="col-md-12">
            <CreateCourse data={data} callback={getData} />
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}