import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { callApi, getInlineLoader } from "../Helpers";
import { MdAdd, MdDelete } from "react-icons/md";
import { STR_ADD, STR_CERTIFICATE_EDU, STR_CERTIFICATES_EARNED, STR_DEGREE_EDU, STR_DELETE, STR_DIPLOMA_EDU, STR_FILE, STR_FULLNAME, STR_HIGH_SCHOOL_EDU, STR_INVALID_CERTIFICATE_BLOCK, STR_INVALID_FULLNAME, STR_MANAGE_EDUCATION_PROFILE, STR_MASTERS_EDU, STR_MAX_EDUCATION_LEVEL, STR_PLEASE_WAIT, STR_PRIMARY_EDU, STR_PROGRAM_NAME, STR_SECONDARY_EDU, STR_SERVER_ERROR, STR_SUCCESSFUL, STR_YEAR } from "../Strings";

export default function View() {
  const appContext = useContext(AppContext);

  /* States */
  const [maxLevel, setMaxLevel] = useState("primary");
	const [fullname, setFullname] = useState(appContext.currentUserData?.fullname || "");
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  /* Add a new certificate block */
  function addCertificate() {
    setCertificates((prev) => [
      ...prev,
      { name: "", file: null, year: "" },
    ]);
  }

  /* Update certificate details */
  function updateCertificate(index, field, value) {
    setCertificates((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  }

  /* Delete a certificate block */
  function deleteCertificate(index) {
    setCertificates((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  }

  /* Validate certificates */
  function validateCertificates() {
    for (let cert of certificates) {
      if (!cert.name.trim() || !cert.file || !cert.year.trim()) {
        appContext.tellError(
          STR_INVALID_CERTIFICATE_BLOCK[appContext.language]
        );
        return false;
      }
    }
    return true;
  }

  /* Submit education profile */
  async function submitEducationProfile() {
    if (!loading) {
      if(fullname && fullname.trim().length > 0) {
				if (validateCertificates()) {
					const certificateFiles = {};
					
					for (let i = 0; i < certificates.length; i++) {
						const cert = certificates[i];
						certificateFiles[`certificateFile${i}`] = cert.file;
					}
	
					setLoading(true);
					await callApi("submit-education-profile", {
						maxLevel,
						certificates: JSON.stringify(
							certificates.map(({ name, year }) => ({ name, year }))
						),
						...certificateFiles,
						fullname,
					})
						.then((response) => {
							if (response.status === 1) {
								appContext.tellMessage(STR_SUCCESSFUL[appContext.language]);
								appContext.navBack();
							} else {
								appContext.tellError(response.msg);
							}
						})
						.catch(() => {
							appContext.tellError(STR_SERVER_ERROR[appContext.language]);
						});
					setLoading(false);
				}
			} else {
				appContext.tellError(STR_INVALID_FULLNAME[appContext.language]);
			}
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading]);

  if (ready) {
    return (
      <div className="container">
        <div className="section-title">{STR_MANAGE_EDUCATION_PROFILE[appContext.language]}</div>
        <div className="body">

					<div className="mb-4">
            <label className="form-label">{STR_FULLNAME[appContext.language]}</label>
            <input
              className="form-control"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">{STR_MAX_EDUCATION_LEVEL[appContext.language]}</label>
            <select
              className="form-control"
              value={maxLevel}
              onChange={(e) => setMaxLevel(e.target.value)}
            >
              <option value="primary">{STR_PRIMARY_EDU[appContext.language]}</option>
              <option value="secondary">{STR_SECONDARY_EDU[appContext.language]}</option>
              <option value="high_school">{STR_HIGH_SCHOOL_EDU[appContext.language]}</option>
              <option value="certificate">{STR_CERTIFICATE_EDU[appContext.language]}</option>
              <option value="diploma">{STR_DIPLOMA_EDU[appContext.language]}</option>
              <option value="degree">{STR_DEGREE_EDU[appContext.language]}</option>
              <option value="masters">{STR_MASTERS_EDU[appContext.language]}</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="form-label">{STR_CERTIFICATES_EARNED[appContext.language]}</label>
            <div style={{ border: "1px solid black", borderRadius: "10px", padding: "10px" }}>
              {certificates.map((cert, i) => (
                <div key={i} className="row mb-3">
                  <div className="col-md-4">
                    <label className="form-label">{STR_PROGRAM_NAME[appContext.language]}</label>
                    <input
                      className="form-control"
                      type="text"
                      value={cert.name}
                      onChange={(e) =>
                        updateCertificate(i, "name", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">{STR_FILE[appContext.language]}</label>
                    <input
                      className="form-control"
                      type="file"
											accept="image/*,application/pdf"
                      onChange={(e) =>
                        updateCertificate(i, "file", e.target.files[0])
                      }
                    />
                  </div>
                  <div className="col-md-2 col-6">
                    <label className="form-label">{STR_YEAR[appContext.language]}</label>
                    <input
                      className="form-control"
                      type="text"
                      value={cert.year}
                      onChange={(e) =>
                        updateCertificate(i, "year", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-2 col-6 text-start">
                    <label className="form-label d-block">{STR_DELETE[appContext.language]}</label>
                    <MdDelete
                      size={32}
                      onClick={() => deleteCertificate(i)}
                      className="d-block text-danger"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              ))}
              <div className="text-end mt-2 mb-2">
                <button onClick={addCertificate} className="btn btn-dark btn-sm">
                  <MdAdd size={24} /> {STR_ADD[appContext.language]}
                </button>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <button
              className="btn btn-primary btn-block"
              onClick={submitEducationProfile}
            >
              Save Profile
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="container mSupportLoading">{getInlineLoader()}</div>;
  }
}
