import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney } from "../Helpers";
import { MdOutlineGroups2, MdPerson } from "react-icons/md";
import { STR_JOB } from "../Strings";

export default function View({ data }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function viewJob() {
    appContext.navTo({
      item: 'view',
      subItem: 'job',
      extraItem: data.jobData.id,
    })
  }

	function viewCV() {
    appContext.navTo({
      item: 'view',
      subItem: 'cv',
      extraItem: data.userData.id,
    })
  }

  return (
    <div
			className="card"
		>
      <div className="card-body">

				<div className="d-flex">
					<MdPerson
						size={42}
						className="text-muted align-self-center"
						style={{
							flexShrink: "1",
						}}
					/>

					<h6
						className="align-self-center mNoMargin font-semi-bold text-secondary"
						style={{
							flexGrow: "1",
							paddingLeft: "10px",
							fontSize: "16px"
						}}
					>
						{data.userData.fullname}
					</h6>
				</div>

				

				<div className="text-end">
					<button
						className="btn btn-sm bg-dark m-1"
						onClick={viewJob}
					>
						{STR_JOB[appContext.language]}
					</button>

					<button
						className="btn btn-sm bg-dark m-1"
						onClick={viewCV}
					>
						CV
					</button>
				</div>

				<h6
					style={{
						fontSize: "12px"
					}}
					className="font-semi-bold text-end text-muted"
				>
					On: {formatDate(data.dateCreated)}
				</h6>

			</div>
    </div>
  )

}