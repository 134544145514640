import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { arrayToString, callApi, CDN_URL, formatDate, formatMoney, getInlineLoader, stringToArray } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';
import { MdDelete } from "react-icons/md";

export default function View({ data, callback }) {

	const appContext = useContext(AppContext);
	const editorRef = useRef(null);


	/* Place states here */
	const [title, setTitle] = useState("");
	const [price, setPrice] = useState(0);
	const [cover, setCover] = useState(null);
	const [tags, setTags] = useState("");
	const [isPaid, setIsPaid] = useState("yes");
	const [description, setDescription] = useState("");
	const [supportCertificates, setSupportCertificates] = useState("yes");
	const [certificatePassmark, setCertificatePassmark] = useState(50);
	const [categories, setCategories] = useState([]);
	const [skills, setSkills] = useState([]);
	const [numberOfParts, setNumberOfParts] = useState(10);


	const [loading, setLoading] = useState(false);
	const [ready, setReady] = useState(false);
	const [opSuccessful, setOpSuccesful] = useState(false);

	async function init() {
		setReady(false);
		await appContext.getAllSkills();
		setReady(true);
	}


	function getCover(e) {
		// Ensure the event has a file input with a file selected
		if (e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];

			// Use FileReader to read the file and generate a URL
			const reader = new FileReader();

			// On load, set the result (URL) to an image src or handle it as needed
			reader.onload = function (event) {
				const fileURL = event.target.result;
				setCover(fileURL); // Replace with your image handling logic
			};

			// Read the file as a Data URL
			reader.readAsDataURL(file);
		} else {
			//..
		}
	}

	function addCategory() {
		setCategories(_prev => {
			const _new = [..._prev];
			_new.push("");

			return _new;
		})
	}

	function deleteCategory(_index) {
		setCategories(_prev => {
			const _new = [..._prev];
			_new.splice(_index, 1);

			return _new;
		})
	}

	function updateCategory(_index, _value) {
		setCategories(_prev => {
			const _new = [..._prev];
			_new[_index] = _value;

			return _new;
		})
	}

	function cleanCategories(_cats) {
		// Trim and filter empty items
		const _cleaned = _cats
			.map(item => item.trim()) // Trim whitespace
			.filter(item => item.length > 0); // Remove empty items

		// Use a Set to remove duplicates and convert back to an array
		return [...new Set(_cleaned)];
	}


	function addSkill() {
		setSkills(_prev => {
			const _new = [..._prev];
			_new.push("");

			return _new;
		})
	}

	function deleteSkill(_index) {
		setSkills(_prev => {
			const _new = [..._prev];
			_new.splice(_index, 1);

			return _new;
		})
	}

	function updateSkill(_index, _value) {
		setSkills(_prev => {
			const _new = [..._prev];
			_new[_index] = _value;

			return _new;
		})
	}

	function cleanSkills(_skills) {
		// Trim and filter empty items
		const _cleaned = _skills
			.map(item => item.trim()) // Trim whitespace
			.filter(item => item.length > 0); // Remove empty items

		// Use a Set to remove duplicates and convert back to an array
		return [...new Set(_cleaned)];
	}


	async function createCourse() {
		if (!loading) {
			const _cover = document.getElementById("_cover").files[0];

			if (Number(numberOfParts) > 0) {
				if (title.trim().length > 0) {
					if (description.trim().length > 10) {
						if(
							(supportCertificates === 'yes' && Number(certificatePassmark) > 0) ||
							supportCertificates === 'no'
						) {
							if(
								(isPaid === 'yes' && Number(price) > 0) ||
								isPaid === 'no' 
							) {
								//..
								if (_cover) {
									setLoading(true);
									await callApi(
										'create-course', 
										{ 
											cover: (_cover) ? _cover : '', 
											title, 
											price, 
											description, 
											numberOfParts, 
											supportCertificates, 
											certificatePassmark, 
											tags: JSON.stringify(stringToArray(tags)), 
											isPaid, 
											categories: JSON.stringify(cleanCategories(categories)),
											skills: JSON.stringify(cleanSkills(skills)) 
										}
									).then((response) => {
										if (response.status === 1) {
											appContext.navBack();
											appContext.getMyPublisherCourses();
											appContext.tellMessage("Course was published successfully");
										} else {
											appContext.tellError(response.msg);
										}
									})
									setLoading(false);
								} else {
									appContext.tellError("Invalid cover image");
								}
								//..
							} else {
								appContext.tellError("Invalid Pricing")
							}
						} else {
							appContext.tellError("Invalid Certificate settings");
						}
					} else {
						appContext.tellError("Invalid description");
					}
				} else {
					appContext.tellError("Invalid title");
				}
			} else {
				appContext.tellError("Invalid number of parts")
			}


		} else {
			appContext.tellInfo("Please wait....");
		}
	}

	async function updateCourse() {
		if (!loading) {
			const _cover = document.getElementById("_cover").files[0];
			if (Number(numberOfParts) > 0) {
				if (title.trim().length > 0) {
					if (description.trim().length > 10) {
						//..
						if(
							(supportCertificates === 'yes' && Number(certificatePassmark) > 0) ||
							supportCertificates === 'no'
						) {
							if(
								(isPaid === 'yes' && Number(price) > 0) ||
								isPaid === 'no' 
							) {
								//..
								setLoading(true);
								await callApi(
									'update-course', 
									{ 
										id: data?.id, 
										cover: (_cover) ? _cover : "", 
										price,
										title, 
										numberOfParts, 
										description, 
										supportCertificates, 
										certificatePassmark, 
										tags: JSON.stringify(stringToArray(tags)), 
										isPaid, 
										skills: JSON.stringify(cleanSkills(skills)) ,
										categories: JSON.stringify(cleanCategories(categories)) 
									}
								).then((response) => {
									if (response.status === 1) {
										appContext.getMyPublisherCourses();
										appContext.tellMessage("Course was updated successfully");

										if (callback) {
											callback();
										}
									} else {
										appContext.tellError(response.msg);
									}
								})
								setLoading(false);
								//..
							} else {
								appContext.tellError("Invalid Pricing")
							}
						} else {
							appContext.tellError("Invalid Certificate settings");
						}
						//..
						
					} else {
						appContext.tellError("Invalid description");
					}
				} else {
					appContext.tellError("Invalid title");
				}
			} else {
				appContext.tellError("Invalid number of parts");
			}

		} else {
			appContext.tellInfo("Please wait....");
		}
	}

	useEffect(() => {
		if (data) {
			setTitle(data.title);
			setPrice(data.price);
			setDescription(data.description);
			setCover(CDN_URL + data.cover);
			setTags(arrayToString(JSON.parse(data.tags)));
			setCategories(JSON.parse(data.categories));
			setSkills(JSON.parse(data.skills));
			setIsPaid(data.isPaid);
			setSupportCertificates(data.supportCertificates);
			setCertificatePassmark(data.certificatePassmark);
			setNumberOfParts(data.numberOfParts);
		}
	}, [data])

	useEffect(() => {
		init();
	}, [])

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);
	}, [loading])

	if(ready) {
		//..
		return (
			<div
				className="container"
			>
				
				<div className="section-title">
					{data ? "Edit this course" : "Create new course"}
				</div>
	
				<div className="body">
					<div className="mb-4">
						<label className="form-label">Title</label>
						<input
							className="form-control"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
	
					<div className="mb-4">
						<label className="form-label">Number Of Parts</label>
						<input
							className="form-control"
							type="text"
							value={numberOfParts}
							onChange={(e) => setNumberOfParts(e.target.value)}
						/>
					</div>
	
					<div className="mb-4">
						<label className="form-label">Is Paid</label>
						<select
							className="form-control"
							type="text"
							value={isPaid}
							onChange={(e) => setIsPaid(e.target.value)}
						>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>

					<div className="mb-4" style={{ display: (isPaid === 'yes') ? 'block' : 'none' }}>
						<label className="form-label">Price</label>
						<input
							className="form-control"
							type="text"
							value={price || "0"}
							onChange={(e) => setPrice(e.target.value)}
						/>
					</div>
	
					<div className="mb-4">
						<label className="form-label">Categories</label>
						<div
							style={{
								width: "100%",
								padding: "10px",
								border: "1px solid black",
								borderRadius: "10px",
							}}
						>
							{
								categories.map((item, i) => {
									return (
										<div className="d-flex mb-1" key={i}>
											<select
												key={i}
												className="form-control align-sel-center"
												value={item}
												onChange={(e) => {
													updateCategory(i, e.target.value)
												}}
												style={{
													flexGrow: "1",
	
													fontSize: "14px",
												}}
											>
												<option value="">Select category</option>
												{
													JSON.parse(appContext.systemParams.contentCategories).map((item, i) => {
														return (
															<option key={i} value={item.id}>{item.id}</option>
														)
													})
												}
											</select>
	
											<MdDelete
												size={24}
												style={{
													flexShrink: "0",
													cursor: "pointer",
												}}
												onClick={() => deleteCategory(i)}
												className="ml-2 text-danger align-self-center"
											/>
										</div>
									)
								})
							}
							<button
								className="btn btn-sm btn-dark"
								onClick={addCategory}
							>
								Add
							</button>
						</div>
					</div>

					<div className="mb-4">
						<label className="form-label">Skills</label>
						<div
							style={{
								width: "100%",
								padding: "10px",
								border: "1px solid black",
								borderRadius: "10px",
							}}
						>
							{
								skills.map((item, i) => {
									return (
										<div className="d-flex mb-1" key={i}>
											<select
												key={i}
												className="form-control align-sel-center"
												value={item}
												onChange={(e) => {
													updateSkill(i, e.target.value)
												}}
												style={{
													flexGrow: "1",
	
													fontSize: "14px",
												}}
											>
												<option value="">Select Skill</option>
												{
													appContext.allSkills?.map((item, i) => {
														return (
															<option key={i} value={item.id}>{item.title}</option>
														)
													})
												}
											</select>
	
											<MdDelete
												size={24}
												style={{
													flexShrink: "0",
													cursor: "pointer",
												}}
												onClick={() => deleteSkill(i)}
												className="ml-2 text-danger align-self-center"
											/>
										</div>
									)
								})
							}
							<button
								className="btn btn-sm btn-dark"
								onClick={addSkill}
							>
								Add
							</button>
						</div>
					</div>
	
					<div className="mb-4" style={{ display: "none" }}>
						<label className="form-label">Support Certificates</label>
						<select
							className="form-control"
							type="text"
							value={supportCertificates}
							onChange={(e) => setSupportCertificates(e.target.value)}
						>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>
	
					{
						(supportCertificates === 'yes') ?
							<div className="mb-4">
								<label className="form-label">Certificate Passmark</label>
								<input
									className="form-control"
									type="text"
									value={certificatePassmark}
									onChange={(e) => setCertificatePassmark(e.target.value)}
								/>
							</div>
							: ""
					}
	
					<div className="mb-4" style={{ display: "none" }}>
						<label className="form-label">Tags</label>
						<input
							className="form-control"
							type="text"
							value={tags}
							onChange={(e) => setTags(e.target.value)}
						/>
						<div className="form-text">
							Separate tags with commas
						</div>
					</div>
	
					<div className="mb-4">
						<label className="form-label">Cover Photo </label>
						{
							(cover) ?
								<img
									width="100%"
									height="auto"
									src={cover}
								/>
								: ""
						}
						<input
							className="form-control"
							type="file"
							accept="image/*"
							id="_cover"
							onChange={(e) => getCover(e)}
						/>
					</div>
	
					<div className="mb-4">
						<label className="form-label">Description</label>
						<textarea
							className="form-control"
							value={description}
							onChange={(e) => {
								setDescription(e.target.value)
							}}
						></textarea>
					</div>
	
					<div className="mb-4">
						<button
							className="btn btn-padded bg-secondary btn-block"
							onClick={(data) ? updateCourse : createCourse}
						>
							Save
						</button>
					</div>
				</div>
			
			</div>
		)
		//..
	} else {
		return (
			<div className="container mSupportLoading">
				{getInlineLoader()}
			</div>
		)
	}

}
