import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { STR_TAKE_QUIZ } from "../Strings";
import { MdPerson } from "react-icons/md";
import Login from "./Login";
import BuyCourseWidget from "../ones/BuyCourseWidget";


export default function View({ contentId, courseId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [contentData, setContentData] = useState(null);
  const [courseData, setCourseData] = useState(null);


  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getCourseData(),
      getContentData(),
    ])
    setReady(true);
  }

  async function getContentData() {
    await callApi("get-course-content-data", { id: contentId }).then(response => {
      if (response.status === 1) {
        setContentData(response.data);
      }
    })
  }

  async function getCourseData() {
    await callApi("get-course-data", { id: courseId }).then(response => {
      if (response.status === 1) {
        setCourseData(response.data);
      }
    })
  }

  useEffect(() => {
    init();
  }, [courseId, contentId])


  if (appContext.auth && appContext.currentUserData) {
    if (ready && contentData && courseData) {
      return (
        <div className="container">
          <div className="row">

            <div className="col-md-12">
              <img
                style={{
                  width: "100%",
                  height: "auto",
                }}
                className="bg-surface"
                src={CDN_URL + contentData.cover}
                alt="Cover Image"
              />
            </div>

            <div className="col-md-12">
              <h6
                className="mSectionTitle"
                style={{
                  borderBottom: "1px solid var(--primaryColor)",
                  paddingBottom: "20px",
                }}
              >
                {contentData.title}

                <span
                  className="d-flex mt-3 font-bold text-secondary"
                  style={{
                    display: "block",
                    fontSize: "16px",
                  }}
                >
                  <MdPerson size={28} className="align-self-center" />
                  <span className="align-self-center">{courseData.authorName}</span>
                </span>

              </h6>
            </div>

            {
              (courseData.isPaid === 'yes') ?
                (appContext.hasUserEnrolledInCourse(courseData.id)) ?
                  <>
                    <div
                      className="mt-4 mb-4 col-md-12"
                    >
                      <div
                        className=""
                        style={{
                          width: "100%",
                          padding: "0",
                          overflow: "hidden",
                        }}
                        dangerouslySetInnerHTML={{ __html: contentData.content }}
                      >

                      </div>
                    </div>
                    {
                      (JSON.parse(contentData.quiz || '[]').length > 0) ?
                        <div
                          className="col-md-12 mb-4 text-center bg-surface"
                          style={{
                            padding: "30px",
                          }}
                        >
                          <button
                            className="btn btn-secondary btn-padded btn-rounded"
                            onClick={() => {
                              appContext.navTo({
                                item: 'view',
                                subItem: 'take-course-quiz',
                                extraItem: courseId,
                                moreItem: contentId,
                              })
                            }}
                          >
                            {STR_TAKE_QUIZ[appContext.language]}
                          </button>
                        </div>
                        : ""
                    }
                  </>
									:
									<div
										className="mt-4 mb-4 col-md-12"
									>
										<BuyCourseWidget data={courseData} />
									</div>
                //..
                :
                <>
                  <div
                    className="mt-4 mb-4 col-md-12"
                  >
                    <div
                      className=""
                      style={{
                        width: "100%",
                        padding: "0",
                        overflow: "hidden",
                      }}
                      dangerouslySetInnerHTML={{ __html: contentData.content }}
                    >

                    </div>
                  </div>
                  {
                    (JSON.parse(contentData.quiz || '[]').length > 0) ?
                      <div
                        className="col-md-12 mb-4 text-center bg-surface"
                        style={{
                          padding: "30px",
                        }}
                      >
                        <button
                          className="btn btn-secondary btn-padded btn-rounded"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'take-course-quiz',
                              extraItem: courseId,
                              moreItem: contentId,
                            })
                          }}
                        >
                          {STR_TAKE_QUIZ[appContext.language]}
                        </button>
                      </div>
                      : ""
                  }
                </>
            }



          </div>
        </div>
      )
    } else {
      return (
        <div
          className="mSupportLoading container"
        >
          {getInlineLoader()}
        </div>
      );
    }

  } else {
    return <Login />
  }



}