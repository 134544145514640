import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople, IoMdEye } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { MdPerson } from "react-icons/md";
import Login from "./Login";
import { STR_CERTIFICATE, STR_COURSE_CERTIFICATES, STR_DEGREE_EDU, STR_DESCRIPTION, STR_DIPLOMA_EDU, STR_EDUCATION_PROFILE, STR_EMAIL, STR_ENTER_EMAIL_ADDRESS, STR_FULLNAME, STR_HIGH_SCHOOL_EDU, STR_JOB_APPLICANTS, STR_JOB_MATCHES, STR_JOB_OPENING, STR_MASTERS_EDU, STR_MAX_EDUCATION_LEVEL, STR_MIN_EDUCATION_LEVEL, STR_NO_RECORDS, STR_OTHER_CERTIFICATION, STR_PHONE_NUMBER, STR_PRIMARY_EDU, STR_PROGRAM_CERTIFICATES, STR_PROGRAM_NAME, STR_REQUIRED_SKILLS, STR_SECONDARY_EDU, STR_SKILLS, STR_SKILLS_ACQUIRED_IN_ACADEMY, STR_YEAR } from "../Strings";
import JobMatches from '../views/JobMatches';
import JobApplicants from '../views/JobApplicants';
import JobApplicationForm from '../views/JobApplicationForm';
import TabbedView from "../components/TabbedView";
import MobileInterface from "../MobileInterface";
import OneProgramCertificate from '../ones/OneProgramCertificate';
import OneCourseCertificate from '../ones/OneCourseCertificate';


export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);
	const [skills, setSkills] = useState(null);
	const [educationProfile, setEducationProfile] = useState({});
	const [programCertificates, setProgramCertificates] = useState(null);
	const [courseCertificates, setCourseCertificates] = useState(null);


	async function init() {
		//run initializations here
		setReady(false);
		await getData();
		setReady(true);
	}

	async function getData() {
		await callApi("get-student-data", { id }).then(response => {
			if (response.status === 1) {
				setData(response.data);
			}
		})
	}

	useEffect(() => {
		if(data) {
			setSkills(JSON.parse(data.skills));
			setEducationProfile(JSON.parse(data.educationProfile));
			setProgramCertificates(JSON.parse(data.programCertificatesEarned));
			setCourseCertificates(JSON.parse(data.courseCertificatesEarned));
		}
	}, [ data ])

	useEffect(() => {
		init();
	}, [id])



	if (ready && data) {
		
		if (appContext.auth && appContext.currentUserData) {
			return (
				<div className="container">
					<div className="row">

						
						<div className="col-md-12">
							<h6
								className="mMainSectionTitle"
								style={{
									borderBottom: "1px solid var(--primaryColor)",
									paddingBottom: "20px",
								}}
							>
								{data.fullname}
								<br/>
								<span style={{ fontSize: "14px" }} className="badge bg-dark">CV</span>
							</h6>
						</div>

						<div className="col-md-4">
							<div className="mt-4 mb-4">
								<label className="form-label">{STR_FULLNAME[appContext.language]}</label>
								<h6
									style={{
										fontSize: "24px",
									}}
								>
									{data.fullname}
								</h6>
							</div>

						</div>

						<div className="col-md-4">
							<div className="mt-4 mb-4">
								<label className="form-label">{STR_EMAIL[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{data.email || 'N/A'}
								</h6>
							</div>
						</div>

						<div className="col-md-4">
							<div className="mt-4 mb-4">
								<label className="form-label">{STR_PHONE_NUMBER[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{data.phone || 'N/A'}
								</h6>
							</div>
						</div>

						<div className="col-md-12">
							<div className="mt-4 mb-4">
								<label className="form-label">{STR_SKILLS_ACQUIRED_IN_ACADEMY[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{
										(skills && skills.length > 0) ?
										<ul className="row">
											{
												skills.map((item, i) => {
													return (
														<li className="col-md-6" key={i}>{appContext.getSkillTitle(item)}</li>
													)
												})
											}
										</ul>
										:"N/A"
									}
								</h6>
							</div>
						</div>

						<div className="col-md-12 mt-4">
							<div className="card">
								<div className="card-body">
									<h6 
										className="card-title"
									>
										{STR_EDUCATION_PROFILE[appContext.language]}
									</h6>

									<div className="mb-4 mt-4">
										<label className="form-label">{STR_MAX_EDUCATION_LEVEL[appContext.language]}</label>
										<h6
										>
											{educationProfile.maxLevel || "N/A"}
										</h6>
									</div>

									<div className="mb-2 mt-2">
										<label className="form-label">{STR_OTHER_CERTIFICATION[appContext.language]}</label>
                    <h6
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {
                        (educationProfile && educationProfile.certificates && educationProfile.certificates.length > 0) ?
                        educationProfile.certificates.map((item, i) => {
                          return (
                            <div className="card" key={i}>
                              <div className="card-body">
                                <h6
                                  style={{
                                    fontSize: "20px"
                                  }}
                                  className="mb-2"
                                >
                                  <span className="d-block font-bold text-muted" style={{ fontSize: "14px" }}>{STR_PROGRAM_NAME[appContext.language]}</span>
                                  {item.name}
                                </h6>

                                <h6
                                  style={{
                                    fontSize: "20px"
                                  }}
                                  className="mb-2"
                                >
                                  <span className="d-block font-bold text-muted" style={{ fontSize: "14px" }}>{STR_YEAR[appContext.language]}</span>
                                  {item.year}
                                </h6>

                                <div
                                  className="text-end"
                                >
                                  <a
                                    className="btn btn-dark btn-sm"
                                    onClick={() => {
																			MobileInterface.openLink(CDN_URL+item.file);
																		}}
                                    target="_blank"
                                  >
                                    <IoMdEye size={24}/>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        :"N/A"
                      }
                    </h6>
									</div>

								</div>
							</div>
						</div>

						<div className="col-md-12 mt-4">
							<div className="card">
								<div className="card-body">
									<h6 
										className="card-title"
									>
										{STR_PROGRAM_CERTIFICATES[appContext.language]}
									</h6>

									{
										(programCertificates && programCertificates.length > 0) ?
											programCertificates.reverse().map((item, i) => {
												return (
													<div className="col-md-12" key={i}>
														<OneProgramCertificate data={item} />
													</div>
												)
											})
											:
											<div className="col-md-12">
												<h6
													style={{
														fontSize: "14px",
														padding: "10px"
													}}
												>
													{STR_NO_RECORDS[appContext.language]}
												</h6>
											</div>
									}
									
								</div>
							</div>
						</div>

						<div className="col-md-12 mt-4">
							<div className="card">
								<div className="card-body">
									<h6 
										className="card-title"
									>
										{STR_COURSE_CERTIFICATES[appContext.language]}
									</h6>

									{
										(courseCertificates && courseCertificates.length > 0) ?
											courseCertificates.reverse().map((item, i) => {
												return (
													<div className="col-md-12" key={i}>
														<OneCourseCertificate data={item} />
													</div>
												)
											})
											:
											<div className="col-md-12">
												<h6
													style={{
														fontSize: "14px",
														padding: "10px"
													}}
												>
													{STR_NO_RECORDS[appContext.language]}
												</h6>
											</div>
									}
									
								</div>
							</div>
						</div>
						
					</div>


					
					
				</div>
			)
		} else {
			return <Login />
		}

	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}



}