import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import logo from '../assets/logo.png';
import logo_worded_only from '../assets/logo_worded_only.png';
import { callApi, getCookie, getInlineLoader, setCookie } from "../Helpers";
import { MdLock, MdLogin, MdOutlinePhonelinkLock, MdPassword, MdPerson, MdPhone, MdPhoneAndroid, MdPhonelinkLock, MdPhoneLocked } from "react-icons/md";
import { GiLovers, GiTalk } from "react-icons/gi";
import { FaUserDoctor } from "react-icons/fa6";
import {
  STR_APP_NAME,
  STR_CHANGE_LANGUAGE,
  STR_CHANGE,
  STR_CONFIRM,
  STR_CONTINUE,
  STR_ENTER,
  STR_ENTER_EMAIL_ADDRESS,
  STR_ENTER_OTP,
  STR_ENTER_YOUR_PHONE_NUMBER,
  STR_FORGOT_PASSWORD,
  STR_GET_STARTED,
  STR_HEALTH,
  STR_INVALID_CONFIRM_PASSWORD,
  STR_INVALID_EMAIL,
  STR_INVALID_OTP,
  STR_INVALID_PASSWORD,
  STR_INVALID_PHONE,
  STR_PASSWORD,
  STR_PLEASE_WAIT,
  STR_RELATIONSHIPS,
  STR_RESEND_OTP,
  STR_STORIES,
  STR_USE_EMAIL,
  STR_USE_PHONE,
  STR_WELCOME,
  STR_LOGIN_TO_CONTINUE,
  STR_LOGIN_NOW
} from "../Strings";

export default function Login({ formOnly = false }) {

  const appContext = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loginStep, setLoginStep] = useState(formOnly ? "identity" : "launch");
  const [identity, setIdentity] = useState('phone');

  async function init() {
    const storedPhone = getCookie("phone");
    const storedEmail = getCookie("email");
    const storedLoginStep = getCookie("loginStep");

    if (storedLoginStep) {
      setLoginStep(storedLoginStep);

      if (storedPhone) {
        setPhone(storedPhone);
      }

      if (storedEmail) {
        setEmail(storedEmail);
      }
    }
  }

  async function resendOtp() {
    if (!loading) {
      if (String(phone).trim().length >= 9 || String(email).trim().length > 0) {

        setLoading(true);
        await callApi(
          "resend-otp",
          {
            phone: `255${parseInt(phone, 10)}`,
            identity,
            email
          }
        ).then((response) => {
          if (response.status === 1) {
            setCookie('phone', phone);
            setCookie('email', email);
            setCookie('loginStep', response.nextLoginStep);
            setLoginStep(response.nextLoginStep);

          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);

      } else {
        appContext.tellError(STR_INVALID_PHONE[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  async function phoneLogin() {
    if (!loading) {
      if (String(phone).trim().length >= 9) {

        setLoading(true);
        await callApi(
          "phone-login",
          {
            phone: `255${parseInt(phone, 10)}`,
          }
        ).then((response) => {
          if (response.status === 1) {
            setCookie('phone', phone);
            setCookie('loginStep', response.nextLoginStep);
            setLoginStep(response.nextLoginStep);

          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);

      } else {
        appContext.tellError(STR_INVALID_PHONE[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  async function emailLogin() {
    if (!loading) {
      if (String(email).trim().length > 0) {

        setLoading(true);
        await callApi(
          "email-login",
          {
            email,
          }
        ).then((response) => {
          if (response.status === 1) {
            setCookie('email', email);
            setCookie('loginStep', response.nextLoginStep);
            setLoginStep(response.nextLoginStep);

          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);

      } else {
        appContext.tellError(STR_INVALID_EMAIL[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  async function forgotPassword() {
    if (!loading) {
      if (String(phone).trim().length >= 9 || String(email).trim().length > 0) {

        setLoading(true);
        await callApi(
          "forgot-password",
          {
            phone: `255${parseInt(phone, 10)}`,
            email,
            identity,
          }
        ).then((response) => {
          if (response.status === 1) {
            setCookie('phone', phone);
            setCookie('loginStep', response.nextLoginStep);
            setLoginStep(response.nextLoginStep);

          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);

      } else {
        appContext.tellError(STR_INVALID_PHONE[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  async function otpLogin() {
    if (!loading) {
      if (otp.trim().length === 6) {
        if (password.trim().length >= 8) {
          if (password === confirm) {
            setLoading(true);
            await callApi(
              "otp-login",
              {
                phone: `255${parseInt(phone, 10)}`,
                otp,
                password,
                identity,
                email,
              }
            ).then((response) => {
              if (response.status === 1) {
                setCookie('phone', '');
                setCookie('email', '');
                setCookie('loginStep', '');

                setCookie("userId", response.user.id);
                setCookie("userToken", response.user.token);

                appContext.refresh(); //refresh

                appContext.tellMessage(STR_WELCOME[appContext.language]);
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoading(false);
          } else {
            appContext.tellError(STR_INVALID_CONFIRM_PASSWORD[appContext.language])
          }
        } else {
          appContext.tellError(STR_INVALID_PASSWORD[appContext.language])
        }
      } else {
        appContext.tellError(STR_INVALID_OTP[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }



  async function passwordLogin() {
    if (!loading) {
      if (password.trim().length >= 8) {
        setLoading(true);
        await callApi(
          "password-login",
          {
            phone: `255${parseInt(phone, 10)}`,
            email,
            identity,
            otp,
            password,
          }
        ).then((response) => {
          if (response.status === 1) {
            setCookie('phone', '');
            setCookie('loginStep', '');

            setCookie("userId", response.user.id);
            setCookie("userToken", response.user.token);

            appContext.refresh();//refresh

            appContext.tellMessage(STR_WELCOME[appContext.language]);
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);

      } else {
        appContext.tellError(STR_INVALID_PASSWORD[appContext.language])
      }

    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (!formOnly) {
    return (
      <div
        className="Login"
      >
        {
          (loginStep === 'launch') ?
            <div
              className="launch mNoScrollBar"
            >
              <div
                className=""
                style={{
                  maxWidth: "500px",
                  margin: "0 auto",
                }}
              >
                <div
                  className=""
                  style={{
                    marginTop: "90px",
                    marginBottom: "30px",
                    width: "100%",
                  }}
                >
                  <img
                    src={logo}
                    style={{
                      height: "calc(2 * var(--topBarHeight))",
                    }}
                  />

                </div>

                <div
                  style={{
                    width: "100%",
                    paddingLeft: "15px",
                  }}
                >

                  <h1
                    style={{
                      fontSize: "40px",
                    }}
                    className="font-light"
                  >
                    SOMA
                    VITABU
                  </h1>

                  <h6
                    style={{
                      fontSize: "18px",
                    }}
                    className="font-semi-bold text-primary mt-4"
                  >
                    {STR_LOGIN_TO_CONTINUE[appContext.language]}
                  </h6>
                </div>

                <div
                  className="text-end"
                  style={{
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <button
                    className="btn btn-padded btn-rounded btn-secondary mShadow3"
                    style={{
                      textTransform: "uppercase",
                    }}
                    onClick={() => setLoginStep("identity")}
                  >
                    {STR_LOGIN_NOW[appContext.language]}
                  </button>
                </div>

                <div
                  className="text-start"
                  style={{
                    paddingLeft: "15px",
                    marginTop: "40px"
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textTransform: 'uppercase',
                    }}
                    className="font-bold text-secondary"
                    onClick={() => {
                      appContext.changeLanguage();
                    }}
                  >
                    {STR_CHANGE_LANGUAGE[appContext.language]}
                  </h6>
                </div>
              </div>



            </div>
            : ""
        }

        {
          (loginStep === 'identity') ?
            <div
              className="launch mNoScrollBar"
            >
              <div
                className="text-center"
                style={{
                  marginTop: "120px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >



                <MdPhoneAndroid
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >


                {
                  (identity === 'phone') ?
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="input-group"
                      >
                        <span
                          className="input-group-text font-semi-bold text-secondary"
                          style={{
                            fontSize: "22px"
                          }}
                        >
                          +255
                        </span>
                        <input
                          className="form-control"
                          onChange={(e) => setPhone(_prev => {
                            if (Number(e.target.value)) {
                              return Number(e.target.value);
                            } else if (String(e.target.value).trim().length === 0) {
                              return "";
                            } else {
                              return _prev;
                            }
                          })}
                          value={phone}
                          maxLength={10}
                          placeholder="XXX XXX XXX"
                          type="text"
                          style={{
                            fontSize: "22px"
                          }}
                        />
                      </div>
                      <div className="form-text mb-3">
                        {STR_ENTER_YOUR_PHONE_NUMBER[appContext.language]}
                      </div>


                      <button
                        className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          marginTop: "50px",
                        }}
                        onClick={phoneLogin}
                      >
                        {STR_CONTINUE[appContext.language]}
                      </button>
                    </div>
                    : ""
                }

                {
                  (identity === 'email') ?
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="input-group"
                      >
                        <input
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          type="text"
                          style={{
                            fontSize: "22px"
                          }}
                        />
                      </div>
                      <div className="form-text mb-3">
                        {STR_ENTER_EMAIL_ADDRESS[appContext.language]}
                      </div>


                      <button
                        className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          marginTop: "50px",
                        }}
                        onClick={emailLogin}
                      >
                        {STR_CONTINUE[appContext.language]}
                      </button>
                    </div>
                    : ""
                }

                <div
                  className="text-start"
                  style={{
                    paddingRight: "10px",
                    marginTop: "40px"
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textTransform: 'uppercase',
                    }}
                    className="font-bold text-secondary"
                    onClick={() => {
                      appContext.changeLanguage();
                    }}
                  >
                    {STR_CHANGE_LANGUAGE[appContext.language]}
                  </h6>
                </div>
              </div>

            </div>
            : ""
        }

        {
          (loginStep === 'otp') ?
            <div
              className="launch mNoScrollBar"
            >
              <div
                className="text-center"
                style={{
                  marginTop: "120px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >



                <MdPhonelinkLock
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >

                <div
                  className="text-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {STR_WELCOME[appContext.language]}
                  </h6>

                  {
                    (identity === 'phone') ?
                      <h6
                        style={{
                          fontSize: "20px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        +255{parseInt(phone, 10)}
                      </h6>
                      : ""
                  }

                  {
                    (identity === 'email') ?
                      <h6
                        style={{
                          fontSize: "14px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        {email}
                      </h6>
                      : ""
                  }
                </div>

                <div
                  className="input-group"
                >
                  <span
                    className="input-group-text font-semi-bold text-secondary"
                    style={{
                      fontSize: "22px"
                    }}
                  >
                    OTP
                  </span>
                  <input
                    className="form-control"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    maxLength={6}
                    placeholder="XXXXXX"
                    type="text"
                    style={{
                      fontSize: "22px"
                    }}
                  />
                </div>
                <div className="form-text mb-3">
                  {STR_ENTER_OTP[appContext.language]}
                </div>

                <div className="mb-2 mt-2">
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">{STR_PASSWORD[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={password}
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                      />
                    </div>

                    <div className="col-6">
                      <label className="form-label">{STR_CONFIRM[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={confirm}
                        type="password"
                        onChange={(e) => {
                          setConfirm(e.target.value)
                        }}
                      />
                    </div>

                  </div>
                </div>

                <button
                  className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                  onClick={otpLogin}
                >
                  {STR_CONTINUE[appContext.language]}
                </button>

                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                  <div
                    className="text-start"
                    style={{
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textTransform: 'uppercase',
                      }}
                      className="font-bold text-secondary"
                      onClick={() => {
                        appContext.changeLanguage();
                      }}
                    >
                      {STR_CHANGE_LANGUAGE[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="text-end"
                    style={{
                      borderRight: "6px solid var(--lightColor)",
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        setLoginStep("identity");
                        setCookie('phone', '', -100000000);
                        setCookie('loginStep', 'identity');
                        setPhone("")
                        setOtp("");
                      }}
                    >
                      {STR_CHANGE[appContext.language]}
                    </h6>

                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        resendOtp();
                      }}
                    >
                      {STR_RESEND_OTP[appContext.language]}
                    </h6>
                  </div>
                </div>

              </div>

            </div>
            : ""
        }

        {
          (loginStep === 'password') ?
            <div
              className="launch mNoScrollBar"
            >
              <div
                className="text-center"
                style={{
                  marginTop: "120px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >


                <MdPhonelinkLock
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >

                <div
                  className="text-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {STR_WELCOME[appContext.language]}
                  </h6>

                  {
                    (identity === 'phone') ?
                      <h6
                        style={{
                          fontSize: "20px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        +255{parseInt(phone, 10)}
                      </h6>
                      : ""
                  }

                  {
                    (identity === 'email') ?
                      <h6
                        style={{
                          fontSize: "14px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        {email}
                      </h6>
                      : ""
                  }
                </div>


                <div className="mb-3 mt-3">
                  <label className="form-label">{STR_PASSWORD[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>

                <button
                  className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                  onClick={passwordLogin}
                >
                  {STR_CONTINUE[appContext.language]}
                </button>

                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                  <div
                    className="text-start"
                    style={{
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textTransform: 'uppercase',
                      }}
                      className="font-bold text-secondary"
                      onClick={() => {
                        appContext.changeLanguage();
                      }}
                    >
                      {STR_CHANGE_LANGUAGE[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="text-end"
                    style={{
                      borderRight: "6px solid var(--lightColor)",
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        setLoginStep("identity");
                        setCookie('phone', '', -100000000);
                        setCookie('loginStep', 'identity');
                        setPhone("")
                        setOtp("");
                      }}
                    >
                      {STR_CHANGE[appContext.language]}
                    </h6>

                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        forgotPassword()
                      }}
                    >
                      {STR_FORGOT_PASSWORD[appContext.language]}
                    </h6>
                  </div>
                </div>

              </div>

            </div>
            : ""
        }
      </div >
    )
  } else {
    return (
      <div
        style={{
          width: "100%",
          position: "relative",
          paddingBottom: "20px"
        }}
        className=""
      >
        {
          (loginStep === 'identity') ?
            <>
              <div
                className="text-center"
                style={{
                  marginTop: "40px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >



                <MdPhoneAndroid
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >


                {
                  (identity === 'phone') ?
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="input-group"
                      >
                        <span
                          className="input-group-text font-semi-bold text-secondary"
                          style={{
                            fontSize: "22px"
                          }}
                        >
                          +255
                        </span>
                        <input
                          className="form-control"
                          onChange={(e) => setPhone(_prev => {
                            if (Number(e.target.value)) {
                              return Number(e.target.value);
                            } else if (String(e.target.value).trim().length === 0) {
                              return "";
                            } else {
                              return _prev;
                            }
                          })}
                          value={phone}
                          maxLength={10}
                          placeholder="XXX XXX XXX"
                          type="text"
                          style={{
                            fontSize: "22px"
                          }}
                        />
                      </div>
                      <div className="form-text mb-3">
                        {STR_ENTER_YOUR_PHONE_NUMBER[appContext.language]}
                      </div>


                      <button
                        className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          marginTop: "50px",
                        }}
                        onClick={phoneLogin}
                      >
                        {STR_CONTINUE[appContext.language]}
                      </button>
                    </div>
                    : ""
                }

                {
                  (identity === 'email') ?
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="input-group"
                      >
                        <input
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          type="text"
                          style={{
                            fontSize: "22px"
                          }}
                        />
                      </div>
                      <div className="form-text mb-3">
                        {STR_ENTER_EMAIL_ADDRESS[appContext.language]}
                      </div>


                      <button
                        className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          marginTop: "50px",
                        }}
                        onClick={emailLogin}
                      >
                        {STR_CONTINUE[appContext.language]}
                      </button>
                    </div>
                    : ""
                }

                <div
                  className="text-start"
                  style={{
                    paddingRight: "10px",
                    marginTop: "40px"
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textTransform: 'uppercase',
                    }}
                    className="font-bold text-secondary"
                    onClick={() => {
                      appContext.changeLanguage();
                    }}
                  >
                    {STR_CHANGE_LANGUAGE[appContext.language]}
                  </h6>
                </div>
              </div>

            </>
            : ""
        }

        {
          (loginStep === 'otp') ?
            <>
              <div
                className="text-center"
                style={{
                  marginTop: "120px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >



                <MdPhonelinkLock
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >

                <div
                  className="text-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {STR_WELCOME[appContext.language]}
                  </h6>

                  {
                    (identity === 'phone') ?
                      <h6
                        style={{
                          fontSize: "20px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        +255{parseInt(phone, 10)}
                      </h6>
                      : ""
                  }

                  {
                    (identity === 'email') ?
                      <h6
                        style={{
                          fontSize: "14px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        {email}
                      </h6>
                      : ""
                  }
                </div>

                <div
                  className="input-group"
                >
                  <span
                    className="input-group-text font-semi-bold text-secondary"
                    style={{
                      fontSize: "22px"
                    }}
                  >
                    OTP
                  </span>
                  <input
                    className="form-control"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    maxLength={6}
                    placeholder="XXXXXX"
                    type="text"
                    style={{
                      fontSize: "22px"
                    }}
                  />
                </div>
                <div className="form-text mb-3">
                  {STR_ENTER_OTP[appContext.language]}
                </div>

                <div className="mb-2 mt-2">
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">{STR_PASSWORD[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={password}
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                      />
                    </div>

                    <div className="col-6">
                      <label className="form-label">{STR_CONFIRM[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={confirm}
                        type="password"
                        onChange={(e) => {
                          setConfirm(e.target.value)
                        }}
                      />
                    </div>

                  </div>
                </div>

                <button
                  className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                  onClick={otpLogin}
                >
                  {STR_CONTINUE[appContext.language]}
                </button>

                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                  <div
                    className="text-start"
                    style={{
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textTransform: 'uppercase',
                      }}
                      className="font-bold text-secondary"
                      onClick={() => {
                        appContext.changeLanguage();
                      }}
                    >
                      {STR_CHANGE_LANGUAGE[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="text-end"
                    style={{
                      borderRight: "6px solid var(--lightColor)",
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        setLoginStep("identity");
                        setCookie('phone', '', -100000000);
                        setCookie('loginStep', 'identity');
                        setPhone("")
                        setOtp("");
                      }}
                    >
                      {STR_CHANGE[appContext.language]}
                    </h6>

                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        resendOtp();
                      }}
                    >
                      {STR_RESEND_OTP[appContext.language]}
                    </h6>
                  </div>
                </div>

              </div>

            </>
            : ""
        }

        {
          (loginStep === 'password') ?
            <>
              <div
                className="text-center"
                style={{
                  marginTop: "120px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >


                <MdPhonelinkLock
                  size={100}
                  className="text-secondary"
                />

              </div>

              <div
                style={{
                  width: "280px",
                  margin: "20px auto",
                }}
              >

                <div
                  className="text-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    {STR_WELCOME[appContext.language]}
                  </h6>

                  {
                    (identity === 'phone') ?
                      <h6
                        style={{
                          fontSize: "20px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        +255{parseInt(phone, 10)}
                      </h6>
                      : ""
                  }

                  {
                    (identity === 'email') ?
                      <h6
                        style={{
                          fontSize: "14px",
                        }}
                        className="font-semi-bold text-secondary"
                      >
                        {email}
                      </h6>
                      : ""
                  }
                </div>


                <div className="mb-3 mt-3">
                  <label className="form-label">{STR_PASSWORD[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>

                <button
                  className="btn btn-padded btn-block btn-primary btn-rounded mShadow2"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                  onClick={passwordLogin}
                >
                  {STR_CONTINUE[appContext.language]}
                </button>

                <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                  <div
                    className="text-start"
                    style={{
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textTransform: 'uppercase',
                      }}
                      className="font-bold text-secondary"
                      onClick={() => {
                        appContext.changeLanguage();
                      }}
                    >
                      {STR_CHANGE_LANGUAGE[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="text-end"
                    style={{
                      borderRight: "6px solid var(--lightColor)",
                      paddingRight: "10px",
                      marginTop: "40px"
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        setLoginStep("identity");
                        setCookie('phone', '', -100000000);
                        setCookie('loginStep', 'identity');
                        setPhone("")
                        setOtp("");
                      }}
                    >
                      {STR_CHANGE[appContext.language]}
                    </h6>

                    <h6
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      className="font-semi-bold"
                      onClick={() => {
                        forgotPassword()
                      }}
                    >
                      {STR_FORGOT_PASSWORD[appContext.language]}
                    </h6>
                  </div>
                </div>

              </div>

            </>
            : ""
        }
      </div>
    )
  }
}