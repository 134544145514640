import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { MdPerson } from "react-icons/md";
import Login from "./Login";
import { STR_CERTIFICATE, STR_DEGREE_EDU, STR_DESCRIPTION, STR_DIPLOMA_EDU, STR_HIGH_SCHOOL_EDU, STR_JOB_APPLICANTS, STR_JOB_MATCHES, STR_JOB_OPENING, STR_MASTERS_EDU, STR_MIN_EDUCATION_LEVEL, STR_PRIMARY_EDU, STR_REQUIRED_SKILLS, STR_SECONDARY_EDU } from "../Strings";
import JobMatches from '../views/JobMatches';
import JobApplicants from '../views/JobApplicants';
import JobApplicationForm from '../views/JobApplicationForm';
import TabbedView from "../components/TabbedView";


export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);
	const [requiredSkills, setRequiredSkills] = useState(null);


	async function init() {
		//run initializations here
		setReady(false);
		await getData();
		setReady(true);
	}

	async function getData() {
		await callApi("get-job-data", { id }).then(response => {
			if (response.status === 1) {
				setData(response.data);
			}
		})
	}

	useEffect(() => {
		if(data) {
			setRequiredSkills(JSON.parse(data.requiredSkills))
		}
	}, [ data ])

	useEffect(() => {
		init();
	}, [id])



	if (ready && data) {
		
		if (appContext.auth && appContext.currentUserData) {
			return (
				<div className="container">
					<div className="row">

						
						<div className="col-md-12">
							<h6
								className="mSectionTitle"
								style={{
									borderBottom: "1px solid var(--primaryColor)",
									paddingBottom: "20px",
								}}
							>
								{data.title}
								<br/>
								<span style={{ fontSize: "14px" }} className="badge bg-dark">{STR_JOB_OPENING[appContext.language]}</span>
							</h6>
						</div>

						<div className="col-md-12">
							<div className="mt-4 mb-4">
								<label className="form-label">{STR_DESCRIPTION[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{data.description}
								</h6>
							</div>

							<div className="mt-4 mb-4">
								<label className="form-label">{STR_MIN_EDUCATION_LEVEL[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{ (data.minEducationLevel === 'primary') ? STR_PRIMARY_EDU[appContext.language] : "" }
									{ (data.minEducationLevel === 'secondary') ? STR_SECONDARY_EDU[appContext.language] : "" }
									{ (data.minEducationLevel === 'high_school') ? STR_HIGH_SCHOOL_EDU[appContext.language] : "" }
									{ (data.minEducationLevel === 'diploma') ? STR_DIPLOMA_EDU[appContext.language] : "" }
									{ (data.minEducationLevel === 'certificate') ? STR_CERTIFICATE[appContext.language] : "" }
									{ (data.minEducationLevel === 'degree') ? STR_DEGREE_EDU[appContext.language] : "" }
									{ (data.minEducationLevel === 'masters') ? STR_MASTERS_EDU[appContext.language] : "" }
								</h6>
							</div>

							<div className="mt-4 mb-4">
								<label className="form-label">{STR_REQUIRED_SKILLS[appContext.language]}</label>
								<h6
									style={{
										fontSize: "16px",
									}}
								>
									{
										(requiredSkills && requiredSkills.length > 0) ?
										<ul>
											{
												requiredSkills.map((item, i) => {
													return (
														<li key={i}>{appContext.getSkillTitle(item)}</li>
													)
												})
											}
										</ul>
										:"N/A"
									}
								</h6>
							</div>

						</div>
						
					</div>


					{
						(Number(appContext.currentUserData.id) === Number(data.userId)) ?
						<div className="card">
							<div className="card-body">
								<TabbedView
									tabs={
										[
											{ id: 0, label: STR_JOB_MATCHES[appContext.language], content: <JobMatches id={id} /> },
											{ id: 1, label: STR_JOB_APPLICANTS[appContext.language], content: <JobApplicants id={id} /> },
										]
									}
								/>
							</div>
						</div>
						:
						<div className="card">
							<div className="card-body">
								<JobApplicationForm id={id}/>
							</div>
						</div>
					}

					
				</div>
			)
		} else {
			return <Login />
		}

	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}



}