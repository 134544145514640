import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { callApi, stringToArray, getInlineLoader } from "../Helpers";
import { MdDelete } from "react-icons/md";
import { STR_CERTIFICATE_EDU, STR_DEGREE_EDU, STR_DIPLOMA_EDU, STR_HIGH_SCHOOL_EDU, STR_MASTERS_EDU, STR_MIN_EDUCATION_LEVEL, STR_PRIMARY_EDU, STR_SECONDARY_EDU } from "../Strings";

export default function View({ data, callback }) {
  const appContext = useContext(AppContext);

  /* States */
  const [title, setTitle] = useState("");
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [minEducationLevel, setMinEducationLevel] = useState("primary");

  /* Initialize available skills */
  async function init() {
    setReady(false);
    await appContext.getAllSkills(); // Fetch all skills
    setReady(true);
  }

  /* Skills management */
  function addSkill() {
    setRequiredSkills((prev) => [...prev, ""]);
  }

  function deleteSkill(index) {
    setRequiredSkills((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  }

  function updateSkill(index, value) {
    setRequiredSkills((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  }

  function cleanSkills(skills) {
    return [...new Set(skills.map((skill) => skill.trim()).filter((skill) => skill))];
  }

  /* Post a Job */
  async function postJob() {
    if (!loading) {
      if (title.trim().length > 0) {
        if (description.trim().length > 10) {
          if (cleanSkills(requiredSkills).length > 0) {
            setLoading(true);
            await callApi("post-job", {
              title,
              description,
              requiredSkills: JSON.stringify(cleanSkills(requiredSkills)),
              minEducationLevel,
            })
              .then((response) => {
                if (response.status === 1) {
                  appContext.tellMessage("Job posted successfully!");
									appContext.navBack();
                  appContext.getAllJobs();
                  if (callback) callback();
                } else {
                  appContext.tellError(response.msg);
                }
              })
              .catch((err) => {
                appContext.tellError("Server error. Please try again.");
              });
            setLoading(false);
          } else {
            appContext.tellError("Please add at least one required skill.");
          }
        } else {
          appContext.tellError("Description must be at least 10 characters.");
        }
      } else {
        appContext.tellError("Title is required.");
      }
    } else {
      appContext.tellInfo("Please wait...");
    }
  }

  /* Initialize on mount */
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading]);

  if (ready) {
    return (
      <div className="container">
        <div className="section-title">{data ? "Edit Job" : "Post a Job"}</div>
        <div className="body">
          <div className="mb-4">
            <label className="form-label">Job Title</label>
            <input
              className="form-control"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          <div className="mb-4">
            <label className="form-label">{STR_MIN_EDUCATION_LEVEL[appContext.language]}</label>
            <select
              className="form-control"
              value={minEducationLevel}
              onChange={(e) => setMinEducationLevel(e.target.value)}
            >
              <option value="primary">{STR_PRIMARY_EDU[appContext.language]}</option>
              <option value="secondary">{STR_SECONDARY_EDU[appContext.language]}</option>
              <option value="high_school">{STR_HIGH_SCHOOL_EDU[appContext.language]}</option>
              <option value="certificate">{STR_CERTIFICATE_EDU[appContext.language]}</option>
              <option value="diploma">{STR_DIPLOMA_EDU[appContext.language]}</option>
              <option value="degree">{STR_DEGREE_EDU[appContext.language]}</option>
              <option value="masters">{STR_MASTERS_EDU[appContext.language]}</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="form-label">Required Skills</label>
            <div style={{ padding: "10px", border: "1px solid black", borderRadius: "10px" }}>
              {requiredSkills.map((item, i) => (
                <div className="d-flex mb-1" key={i}>
                  <select
                    className="form-control"
                    value={item}
                    onChange={(e) => updateSkill(i, e.target.value)}
                    style={{ flexGrow: 1, fontSize: "14px" }}
                  >
                    <option value="">Select Skill</option>
                    {appContext.allSkills?.map((skill, idx) => (
                      <option key={idx} value={skill.id}>
                        {skill.title}
                      </option>
                    ))}
                  </select>
                  <MdDelete
                    size={24}
                    style={{ flexShrink: 0, cursor: "pointer" }}
                    onClick={() => deleteSkill(i)}
                    className="ml-2 text-danger align-self-center"
                  />
                </div>
              ))}
              <button className="btn btn-sm btn-dark" onClick={addSkill}>
                Add Skill
              </button>
            </div>
          </div>

          <div className="mb-4">
            <button
              className="btn btn-padded bg-secondary btn-block"
              onClick={postJob}
              disabled={loading}
            >
              {data ? "Update Job" : "Post Job"}
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="container mSupportLoading">{getInlineLoader()}</div>;
  }
}
