import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_BOOK_SHOP, STR_BOOKS, STR_COURSES, STR_MENU_ACCOUNT, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_BOOKS, STR_SHOP } from "../Strings";
import ArticlesHome from '../views/ArticlesHome';
import CoursesHome from '../views/CoursesHome';
import ShopHome from '../views/ShopHome';
import AccountHome from '../views/AccountHome';
import Account from "../main-views/Account";
import DigitalPurchases from "../views/DigitalPurchases";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        className="HomeLanding"
      >
        <TabbedView
          tabs={
            [              
              { id: 0, label: STR_BOOK_SHOP[appContext.language], content: <ShopHome /> },
              { 
                id: 1, 
                label: STR_MY_BOOKS[appContext.language], 
                content: (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "scroll",
                    paddingBottom: "40px",
                  }}
                  className="mNoScrollBar"
                >
                  <div className="container">
                    <DigitalPurchases/>
                  </div>
                </div>) 
              },
              { 
                id: 2, 
                label: STR_MENU_ACCOUNT[appContext.language], 
                content: (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "scroll",
                    paddingBottom: "40px",
                  }}
                  className="mNoScrollBar"
                >
                  <div className="container">
                    <Account/>
                  </div>
                </div>) 
              },
            ]
          }
        />
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}