import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { callApi, CDN_URL, formatMoney, remove255 } from "../Helpers";
import { STR_BUY_NOW, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_INVALID_PAYMENT_METHOD, STR_INVALID_QUANTITY, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PAYMENT_METHOD, STR_PHONE_NUMBER, STR_PRICE, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_SUBSCRIPTION_HAS_EXPIRED } from "../Strings";
import MobileInterface from "../MobileInterface";
import { MdCheckCircle } from "react-icons/md";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */
	const [payWith, setPayWith] = useState("mobile_money");
  const [payPhone, setPayPhone] = useState(remove255(appContext.currentUserData?.phone));
  const [opSuccessful, setOpSuccessful] = useState(false);
	const [loading, setLoading] = useState(false);
	const [totalPrice, setTotalPrice] = useState(getTotalPrice());
	const [quantity, setQuantity] = useState(1);
  

	function getTotalPrice() {
		if(data) {
			return data.price;
		}
	}

	async function buy() {
    if (!loading) {
      if (Number(quantity) > 0) {
        if ((payWith === 'mobile_money' && payPhone.trim().length > 0) || payWith === 'credit_card') {
          setLoading(true);
					await callApi("create-course-order", { itemId: data.id, quantity, payWith, payPhone }).then(response => {
						if (response.status === 1) {
							setOpSuccessful(true)
							if (payWith === 'credit_card') {
								MobileInterface.openLink(response.payment_gateway_url);
							}
						} else {
							appContext.tellError(response.msg);
						}
					})
					setLoading(false);
        } else {
          appContext.tellError(STR_INVALID_PAYMENT_METHOD[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_QUANTITY[appContext.language]);
      }
    }
  }

	useEffect(() => {
		getTotalPrice();
	}, [ data ])

	useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  return (
    <div
      className="card"
    >
      <div className="card-body">
				{
					(opSuccessful) ?
						<div className="mt-4 mb-4 text-center" style={{ width: "100%", padding: "40px" }}>

							<h1
								style={{
									fontSize: "20px",
								}}
							>
								<MdCheckCircle size={70} className="text-secondary" /> <br />
								{(payWith === 'mobile_money') ? STR_FINALIZE_PAYMENT_MOBILE[appContext.language] : STR_FINALIZE_PAYMENT_CARD[appContext.language]}
							</h1>
						</div>
						:
						<>
							<div className="row">
								
								<div className="col-12 mb-2 mt-2">
									<label className="form-label">{STR_PRICE[appContext.language]} (TZS)</label>
									<h6
										style={{
											fontSize: "32px",
										}}
									>
										{formatMoney(totalPrice)}
									</h6>
								</div>

								<div className="col-12">
									<div className="mt-2 mb-2">
										<label className="form-label">{STR_PAYMENT_METHOD[appContext.language]}</label>
										<select
											className="form-control"
											value={payWith}
											onChange={(e) => setPayWith(e.target.value)}
											style={{
												border: "1px solid var(--secondaryColor)",
											}}
										>
											<option value="credit_card">{STR_PAY_WITH_CARD[appContext.language]}</option>
											<option value="mobile_money">{STR_PAY_WITH_MOBILE[appContext.language]}</option>
										</select>
									</div>

									{
										(payWith === 'mobile_money') ?
											<div className="mt-2 mb-2">
												<label className="form-label">{STR_PHONE_NUMBER[appContext.language]}</label>
												<input
													className="form-control"
													value={payPhone}
													onChange={(e) => setPayPhone(e.target.value)}
													style={{
														border: "1px solid var(--secondaryColor)",
													}}
												/>
												<div className="form-text">{STR_ENTER_PHONE_NUMBER_FOR_PAYMENT[appContext.language]}</div>
											</div>
											: ""
									}


								</div>

								<div className="col-12 mb-4 mt-4 text-center">
									<button
										className="btn btn-padded btn-rounded btn-primary btn-block"
										onClick={buy}
									>
										{STR_BUY_NOW[appContext.language]}
									</button>
								</div>

							</div>
						</>
				}
			
      </div>
    </div>
  );
}
