import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney } from "../Helpers";

export default function View({ data }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function view() {
    appContext.navTo({
      item: 'side-view',
      subItem: 'course-content-options',
      extraItem: data.id,
    })
  }

  return (
    <div className="One" onClick={view}>
      <h1
        style={{
          fontSize: "18px"
        }}
        className="font-semi-bold"
      >
        {data.title}
      </h1>

      <h6
        className=""
        style={{
          fontSize: "12px"
        }}
      >
        Created on: {formatDate(data.dateCreated).substring(0, 10)}
      </h6>
    </div>
  )

}