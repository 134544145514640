import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { callApi, CDN_URL, formatDate, formatMoney, getInlineLoader, getInlineLoaderMin, remove255 } from "../Helpers";
import { STR_BUY_NOW, STR_CANCEL, STR_CLICK_TO_PICK_IMAGE, STR_COULD_NOT_FETCH_DATA_CHECK_NET, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_INVALID_PAYMENT_METHOD, STR_INVALID_QUANTITY, STR_NO_MESSAGES, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PAYMENT_METHOD, STR_PHONE_NUMBER, STR_PRICE, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_SEND, STR_SUBSCRIPTION_HAS_EXPIRED } from "../Strings";
import MobileInterface from "../MobileInterface";
import { MdCheckCircle, MdImage, MdOutlineGroups2 } from "react-icons/md";
import { IoIosAttach, IoIosSend } from "react-icons/io";

export default function View({ groupId }) {
  const appContext = useContext(AppContext);

  /* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

	const [messageBody, setMessageBody] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [messages, setMessages] = useState([]);

  const [showAttacher, setShowAttacher] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

	async function init() {
		setReady(false);
		await getData();
		setReady(true);
	}

	function getSelectedImage(e) {
		// Ensure the event has a file input with a file selected
		if (e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];

			// Use FileReader to read the file and generate a URL
			const reader = new FileReader();

			// On load, set the result (URL) to an image src or handle it as needed
			reader.onload = function(event) {
				const fileURL = event.target.result;
				setSelectedImage(fileURL); // Replace with your image handling logic
			};

			// Read the file as a Data URL
			reader.readAsDataURL(file);
		} else {
      //..
		}
	}

	async function sendMessage() {
		return;
		
    if (!isSendingMessage) {
      const messageAttachment = document.getElementById('_imagePicker').files[0];

      if (messageBody.trim().length > 0 || messageAttachment) {
        setIsSendingMessage(true);
        await callApi(
          "send-chatroom-message",
          {
            messageBody,
            messageAttachment: messageAttachment ? messageAttachment : '',
            chatRoomId: data.id,
          }
        ).then(response => {
          if (response.status === 1) {
            setMessageBody("");
            document.getElementById('_imagePicker').value = '';
            getData();
            appContext.getMyChatRooms();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setIsSendingMessage(false);
      }
    }
  }

	async function getData() {
		await callApi("get-course-data", { id: groupId }).then(response => {
			if(response.status === 1) {
				setData(response.data);
			} else {
				appContext.tellError(STR_COULD_NOT_FETCH_DATA_CHECK_NET[appContext.language]);
			}
		})
	}

	useEffect(() => {
		init();
	}, [ groupId ])

	useEffect(() => {
    appContext.setShowOverlayLoader(loading);

		return () => {
			appContext.setShowOverlayLoader(false);
		}
  }, [loading])

  if(ready) {
		return (
			<div className="ChatRoomContainer">
				<div className="ChatRoom">
					<div className="header">
						<div
							className="d-flex bg-surface mShadow3 align-self-between"
							style={{
								height: "100%",
								width: "100%",
								borderRadius: "15px",
								padding: "10px",
							}}
						>
							<MdOutlineGroups2 size={30} className="align-self-center mNoMargin text-secondary" style={{ flexShrink: "0" }} />

							<h6
								className="align-self-center mNoMargin mOneLineText font-bold text-secondary"
								style={{
									fontSize: "14px",
									paddingLeft: "5px",
									paddingRight: "20px",
									textTransform: "capitalize",
									flexGrow: "1",
								}}
							>
								{data.title}
							</h6>

							
						</div>
					</div>

					<div className="body">
						{
							(messages && messages.length > 0) ?
								messages.map((item, i) => {
									return (
										<div
											key={i}
											className="message"
											id={item.messageId}
											data-from-participant={(Number(item.userId) === Number(appContext.currentUserData.id))}
										>
											<div className="content">

												{
													(item.messageBody) ?
													<pre>{item.messageBody}</pre>
													:""
												}

												{
													(item.messageAttachment) ?
													<img
														src={CDN_URL+item.messageAttachment}
														style={{
															width: "100%",
															height: "auto",
														}}
													/>
													:""
												}

												<h6
													style={{
														fontSize: "10px",
														marginTop: "8px",
													}}
												>
													{formatDate(item.timestamp)}
												</h6>
											</div>
										</div>
									)
								})
								:
								<h6
									style={{
										fontSize: "12px",
										padding: "20px",
									}}
								>
									{STR_NO_MESSAGES[appContext.language]}
								</h6>
						}
					</div>

					<div className="footer bg-background">
						<div
							className="d-flex"
							style={{
								height: "100%",
								width: "100%",
								padding: "10px",
							}}
						>
							<textarea
								className="form-control"
								style={{
									height: "100%",
									border: "1px solid var(--secondaryColor)",
								}}
								onChange={(e) => {
									setMessageBody(e.target.value);
								}}
								readOnly={isSendingMessage}
								value={messageBody}
								maxLength={1000}
							></textarea>

							<IoIosAttach
								className="text-primary align-self-center"
								size={30}
								style={{
									flexShrink: "0",
									marginLeft: "5px",
									marginRight: "5px",
								}}
								onClick={() => {
									setShowAttacher(true);
								}}
							/>

							<div
								className="mSupportLoadingMin align-self-center"
								style={{
									flexShrink: "0",
									marginLeft: "5px",
									marginRight: "5px",
								}}
							>
								{
									(isSendingMessage) ?
										getInlineLoaderMin()
										:
										<IoIosSend
											className="text-primary"
											size={30}
											onClick={sendMessage}
										/>
								}
							</div>

						</div>
					</div>

					<div 
						className="attacher"
						style={{
							display: (showAttacher) ? "block" : "none",
						}}
					>
						<div
							style={{
								height: "10%",
								width: "100%"
							}}
							className="text-center"
						>
							<h6
								style={{
									fontSize: "12px"
								}}
							>
								{STR_CLICK_TO_PICK_IMAGE[appContext.language]}
							</h6>
						</div>

						<div
							style={{
								height: "70%",
								width: "100%"
							}}
							className="text-center"
							onClick={() => {
								document.getElementById('_imagePicker').click();
							}}
						>
							<input
								hidden
								type="file"
								accept="image/*"
								id="_imagePicker"
								onChange={getSelectedImage}
							/>
							{
								(selectedImage) ?
								<img
									style={{
										width: "100%",
										height: "100%",
										objectFit: "contain",
									}}
									src={selectedImage}
								/>
								:
								<MdImage
									style={{
										width: "100%",
										height: "100%",
										opacity: "0.4"
									}}
								/>
							}
						</div>

						<div
							style={{
								height: "20%",
								width: "100%"
							}}
							className="text-center"
						>
							<button
								className="btn btn-sm btn-rounded m-2 btn-primary"
								onClick={() => {
									setShowAttacher(false);
									setSelectedImage(null);
									sendMessage();
								}}
							>
								{STR_SEND[appContext.language]}
							</button>

							<button
								className="btn btn-sm btn-rounded m-2 btn-secondary"
								onClick={() => {
									setShowAttacher(false);
									document.getElementById('_imagePicker').value = '';
									setSelectedImage(null);
								}}
							>
								{STR_CANCEL[appContext.language]}
							</button>
						</div>
					</div>

				</div>
			</div>
		)
	} else {
		return (
			<div className="container mSupportLoading">
				{getInlineLoader()}
			</div>
		)
	}
}
