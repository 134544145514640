import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { callApi, getInlineLoader } from "../Helpers";
import {
	STR_ALREADY_TRAINER,
	STR_BECOME_TRAINER,
  STR_BIO,
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_COURSE_CERTIFICATES,
  STR_EDIT_NOW,
  STR_FULLNAME,
  STR_INVALID_BIO,
  STR_INVALID_FULLNAME,
  STR_INVALID_USERNAME,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NO_RECORDS,
  STR_NOTIFICATIONS,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PLEASE_UPDATE_FULLNAME_FOR_CERTIFICATES,
  STR_PLEASE_WAIT,
  STR_PURCHASED_BOOK,
  STR_SEND_REQUEST,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_SUCCESS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME,
	STR_YOUR_REQUEST_IS_BEING_PROCESSED
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdCheck, MdCheckCircle, MdInfoOutline, MdList, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart, MdWarning } from 'react-icons/md';
import OneNotification from '../ones/OneNotification';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
	const [fullname, setFullname] = useState("");
	const [bio, setBio] = useState("");
	const [loading, setLoading] = useState(false);


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }

	async function sendRequest() {
		if(!loading) {
			if(fullname && fullname.trim().length > 0) {
				if(bio && bio.trim().length > 0) {
					setLoading(true);
					await callApi(
						'request-publishing',
						{
							publisherFullname: fullname,
							publisherBio: bio,
						}
					).then(response => {
						if(response.status === 1) {
							appContext.tellMessage(STR_SUCCESS[appContext.language]);
							appContext.getCurrentUserData();
						} else {
							appContext.tellError(response.msg);
						}
					})
					setLoading(false);
				} else {
					appContext.tellError(STR_INVALID_BIO[appContext.language]);
				}
			} else {
				appContext.tellError(STR_INVALID_FULLNAME[appContext.language]);
			}
		} else {
			appContext.tellWarning(STR_PLEASE_WAIT[appContext.language]);
		}
	}

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (appContext.currentUserData) {
      setFullname(appContext.currentUserData.publisherFullname);
			setBio(appContext.currentUserData.publisherBio);
    }
  }, [appContext.currentUserData]);

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);

		return () => {
			appContext.setShowOverlayLoader(false);
		}
	}, [ loading ])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <h1
            className="mSectionTitle text-center"
          >
            {STR_BECOME_TRAINER[appContext.language]}
          </h1>

          <div className="row">
						<div className="col-md-12">
							
							<div
								className=""
								style={{
									maxWidth: "600px",
									margin: "0 auto",
								}}
							>
								
								{
									(
										appContext.currentUserData.isPublisher === 'no'
									) ?
									<>
										{
											(
												appContext.currentUserData.publisherStatus === 'unrequested' ||
												appContext.currentUserData.publisherStatus === 'rejected'
											) ?
											<>
												<div className="mb-2 mt-2">
													<label>{STR_FULLNAME[appContext.language]}</label>
													<input
														className="form-control"
														value={fullname || ''}
														onChange={(e) => setFullname(e.target.value)}
													/>
												</div>

												<div className="mb-2 mt-2">
													<label>{STR_BIO[appContext.language]}</label>
													<textarea
														className="form-control"
														value={bio || ''}
														onChange={(e) => setBio(e.target.value)}
														rows={5}
													/>
												</div>

												<div className="mb-4 mt-4 text-end">
													<button
														className="btn btn-primary btn-padded btn-rounded"
														onClick={sendRequest}
													>
														{STR_SEND_REQUEST[appContext.language]}
													</button>
												</div>
											</>
											: ""
										}

										{
											(
												appContext.currentUserData.publisherStatus === 'requested'
											) ?
											<>
												<div className="mb-2 mt-2">
													<h6
														style={{
															fontSize: "24px"
														}}
														className="text-center"
													>
														<MdCheckCircle className="text-success" size={50}/>
														<br/>
														{STR_YOUR_REQUEST_IS_BEING_PROCESSED[appContext.language]}
													</h6>
												</div>

												
											</>
											: ""
										}
									</>
									:
									<h6
										style={{
											fontSize: "24px"
										}}
									>
										{STR_ALREADY_TRAINER[appContext.language]}
									</h6>
								}

							</div>

						</div>
          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}