import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney, removeSlashes, replaceSlashesWithSpaces } from "../Helpers";
import { STR__ORDER_STATUS_INQUIRY, STR_AUDIO, STR_DOWNLOAD, STR_OPEN, STR_OPEN_AUDIO, STR_OPEN_BOOK, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS, STR_PDF, STR_VIEW_ALL_BOOKS } from "../Strings";
import { MdClose, MdDownload } from "react-icons/md";
import MobileInterface from "../MobileInterface";

export default function View({ data, bookIndex = null, selectedBook = null, setSelectedBook = null }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function openBook() {
    //launch book reader
    appContext.navTo({
      item: "view",
      subItem: "book-reader",
      extraItem: removeSlashes(data.itemFile),
    })

  }

  function openAudio(file, title) {
    //launch book reader
    appContext.navTo({
      item: "view",
      subItem: "audio-listener",
      extraItem: removeSlashes(file),
      moreItem: replaceSlashesWithSpaces(title),
    })

  }

  function download() {
    MobileInterface.saveForOffline(JSON.stringify(data));
  }

  function select() {
    if (data.itemType === 'digital' || data.itemType === 'digital_physical') {
      if (setSelectedBook) {
        setSelectedBook(data)
      }
    }
  }

  function unselect() {
    if (setSelectedBook) {
      setSelectedBook(null);
    }
  }

  useEffect(() => {

  }, [selectedBook])

  if (selectedBook) {
    if (selectedBook.itemId === data.itemId && selectedBook.orderId === data.orderId) {
      return (
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">

                <div className="col-md-12 text-end">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={unselect}
                  >
                    {STR_VIEW_ALL_BOOKS[appContext.language]}
                  </button>
                  <hr />
                </div>

                <div className="col-12 mb-4">
                  <h6
                    style={{
                      fontSize: "22px"
                    }}
                    className="font-semi-bold text-secondary"
                  >
                    {selectedBook.itemTitle}

                  </h6>

                  {
                    (selectedBook.itemType === 'digital' || selectedBook.itemType === 'digital_physical') ?
                    <div className="mt-4">
                      <button
                        className="btn btn-dark btn-rounded m-1"
                        onClick={download}
                      >
                        <MdDownload/> {STR_DOWNLOAD[appContext.language]}
                      </button>
                      <hr/>
                    </div>
                    :""
                  }
                </div>

                <div className="col-12 mt-4 mb-4">
                  <h6
                    style={{
                      fontSize: "18px",
                    }}
                    className="font-bold text-muted"
                  >
                    {STR_PDF[appContext.language]}
                  </h6>

                  {
                    (data.itemFile) ?

                      <button
                        className="btn btn-secondary btn-sm btn-rounded"
                        onClick={openBook}
                      >
                        {STR_OPEN_BOOK[appContext.language]}
                      </button>

                      : "N/A"
                  }
                </div>

                <div className="col-12 mt-4 mb-4">
                  <h6
                    style={{
                      fontSize: "18px",
                    }}
                    className="font-bold text-muted"
                  >
                    {STR_AUDIO[appContext.language]}
                  </h6>

                  {
                    (data.itemAudioFiles && JSON.parse(data.itemAudioFiles).length > 0) ?
                      JSON.parse(data.itemAudioFiles).map((item, i) => {
                        return (
                          <div key={i} className="" style={{ borderBottom: "1px solid var(--secondaryColor)" }}>
                            <h6
                              className=""
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              {item.title}
                            </h6>

                            <div className="text-end">
                              <button
                                className="btn btn-secondary btn-sm btn-rounded m-2"
                                onClick={() => {
                                  openAudio(item.file, item.title)
                                }}
                              >
                                {STR_OPEN_AUDIO[appContext.language]}
                              </button>
                            </div>
                          </div>
                        )
                      })
                      : "N/A"
                  }
                </div>



              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }

  } else {
    return (
      <div className="col-md-4 col-6">
        <div className="card" onClick={select} style={{ height: "250px" }}>
          <div className="card-body" style={{ position: "relative" }}>
            <div className="row">
              <h6
                style={{
                  fontSize: "18px"
                }}
                className="font-bold text-secondary three-lines-text"
              >
                {data.itemTitle}

              </h6>

              {
                (data.itemType === 'physical') ?
                  <h6
                    style={{
                      fontSize: "30px"
                    }}
                    className="font-light"
                  >
                    {data.quantity}

                    <span
                      style={{
                        display: "block",
                        fontSize: "12px"
                      }}
                      className="font-regular"
                    >
                      {data.unit}
                    </span>
                  </h6>
                  : ""
              }



              <h6
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  bottom: "0",
                }}
                className="font-light"
              >

                {formatDate(data.dateDelivered)}

              </h6>

            </div>
          </div>
        </div>
      </div>
    )
  }

}