import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { formatMoney, getInlineLoader, setCookie } from "../Helpers";
import {
  STR_BECOME_TRAINER,
  STR_BOOKS,
  STR_CERTIFICATE_PROGRAMS,
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_COURSE_CERTIFICATES,
  STR_COURSES,
  STR_LOGOUT,
  STR_MENU_ARTICLES,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NOTIFICATIONS,
  STR_OTHER_PURCHASES,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PROGRAM_CERTIFICATES,
  STR_PUBLISHER_DASHBOARD,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME,
  STR_WALLET_BALANCE
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdList, MdLogout, MdNotifications, MdOutlineNotifications, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart, MdTranslate } from 'react-icons/md';
import { GrCertificate } from "react-icons/gr";
import { TbNotification } from "react-icons/tb";
import { GiTeacher } from "react-icons/gi";
import BecomeTrainer from '../views/BecomeTrainer';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      appContext.getCurrentUserData(),
      appContext.getMyPublisherBooks(),
      appContext.getMyPublisherCourses(),
      appContext.getMyPublisherArticles(),
      appContext.getMyPublisherCertificatePrograms(),
      appContext.getMyPublisherWalletBalance(),
    ])
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])



  if (ready) {
    if(appContext.currentUserData.isPublisher === 'yes') {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
          }}
          className="mNoScrollBar"
        >
          <div className="container">
  
            <div className="row">

              <div className="col-md-12">
                <h6 className="main-section-title">
                  {STR_PUBLISHER_DASHBOARD[appContext.language]}
                </h6>
              </div>


              <div className="col-md-12">
                <div 
                  className="card" 
                  style={{ 
                    cursor: "pointer" 
                  }}
                  
                >
                  <div className="card-body">
                    <h6
                      style={{
                        fontSize: "42px"
                      }}
                      className="font-semi-bold text-success"
                    >
                      {formatMoney(appContext.myPublisherWalletBalance)}
                      <span
                        style={{
                          fontSize: "16px"
                        }}
                        className="d-block text-muted"
                      >
                        {STR_WALLET_BALANCE[appContext.language]}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>


              <div className="col-md-6">
                <div 
                  className="card" 
                  style={{ 
                    cursor: "pointer" 
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'account',
                      subItem: 'publisher-certificate-programs'
                    })
                  }}
                >
                  <div className="card-body">
                    <h6
                      style={{
                        fontSize: "32px"
                      }}
                      className="font-semi-bold"
                    >
                      {formatMoney(appContext.myPublisherCertificatePrograms?.length)}
                      <span
                        style={{
                          fontSize: "16px"
                        }}
                        className="d-block text-muted"
                      >
                        {STR_CERTIFICATE_PROGRAMS[appContext.language]}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div 
                  className="card" 
                  style={{ 
                    cursor: "pointer" 
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'account',
                      subItem: 'publisher-courses'
                    })
                  }}
                >
                  <div className="card-body">
                    <h6
                      style={{
                        fontSize: "32px"
                      }}
                      className="font-semi-bold"
                    >
                      {formatMoney(appContext.myPublisherCourses?.length)}
                      <span
                        style={{
                          fontSize: "16px"
                        }}
                        className="d-block text-muted"
                      >
                        {STR_COURSES[appContext.language]}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div 
                  className="card" 
                  style={{ 
                    cursor: "pointer" 
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'account',
                      subItem: 'publisher-articles'
                    })
                  }}
                >
                  <div className="card-body">
                    <h6
                      style={{
                        fontSize: "32px"
                      }}
                      className="font-semi-bold"
                    >
                      {formatMoney(appContext.myPublisherArticles?.length)}
                      <span
                        style={{
                          fontSize: "16px"
                        }}
                        className="d-block text-muted"
                      >
                        {STR_MENU_ARTICLES[appContext.language]}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div 
                  className="card" 
                  style={{ 
                    cursor: "pointer" 
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'account',
                      subItem: 'publisher-books'
                    })
                  }}
                >
                  <div className="card-body">
                    <h6
                      style={{
                        fontSize: "32px"
                      }}
                      className="font-semi-bold"
                    >
                      {formatMoney(appContext.myPublisherBooks?.length)}
                      <span
                        style={{
                          fontSize: "16px"
                        }}
                        className="d-block text-muted"
                      >
                        {STR_BOOKS[appContext.language]}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
  
  
        </div>
      )
    } else {
      return <BecomeTrainer/>
    }
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}