const MobileInterface = {
  openLink(url) {

    if (window.MobileInterface) {
      //use android interface
      window.MobileInterface.openLink(url)
    } else {
      //try ios interface
      try {
        window.webkit.messageHandlers.openLink.postMessage(url);
      } catch (error) {
        //fallback
        //erro
      }
    }
  },

  setOneSignalExternalUserId(id) {

    if (window.MobileInterface) {
      //use android interface
      window.MobileInterface.setOneSignalExternalUserId(id);
    } else {
      //try ios interface
      try {
        window.webkit.messageHandlers.setOneSignalExternalUserId.postMessage(id);
      } catch (error) {
        //fallback
        //erro
      }
    }
  },

  saveForOffline(metaData) {

    if (window.MobileInterface) {
      //use android interface
      window.MobileInterface.saveForOffline(metaData);
    } else {
      //try ios interface
      try {
        window.webkit.messageHandlers.saveForOffline.postMessage(metaData);
      } catch (error) {
        //fallback
        //erro
      }
    }
  },

  getSavedBooks() {

    if (window.MobileInterface) {
      //use android interface
      return window.MobileInterface.getSavedBooks();
    } else {
      //try ios interface
      try {
        return window.webkit.messageHandlers.getSavedBooks.postMessage();
      } catch (error) {
        //fallback
        //erro
      }
    }
  },

  getBaseStorageFolder() {

    if (window.MobileInterface) {
      //use android interface
      return window.MobileInterface.getBaseStorageFolder();
    } else {
      //try ios interface
      try {
        return window.webkit.messageHandlers.getBaseStorageFolder.postMessage();
      } catch (error) {
        //fallback
        //erro
      }
    }
  },
}

export default MobileInterface;