import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import OneArticle from '../ones/OneArticle';
import CreateArticle from '../views/CreateArticle';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi("get-article-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError("Could not fetch data, check your connection");
      }
    })
  }

  async function publish() {
    setLoading(true);
    await callApi("publish-article", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getMyPublisherArticles();
      } else {
        appContext.tellError("Could not publish, check your connection");
      }
    })
    setLoading(false);
  }

  async function unpublish() {
    setLoading(true)
    await callApi("unpublish-article", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getMyPublisherArticles();
      } else {
        appContext.tellError("Could not unpublish, check your connection");
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              {data.title}
            </h6>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Actions</h6>
                <hr />


                {
                  (data.status === 'unpublished') ?
                    <button
                      className="btn btn-outline-dark m-2"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "This content will be published, continue?",
                          onConfirm: publish,
                        })
                      }}
                    >
                      Publish
                    </button>
                    : ""
                }

                {
                  (data.status === 'published') ?
                    <button
                      className="btn btn-outline-danger m-2"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "This content will be unpublished, continue?",
                          onConfirm: unpublish,
                        })
                      }}
                    >
                      Unpublish
                    </button>
                    : ""
                }

              </div>

            </div>
          </div>

          <div className="col-md-12">
            <CreateArticle data={data} callback={getData} />
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}