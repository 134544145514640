import { useContext, useState, useEffect } from "react";
import { AppContext } from "../App";
import { callApi, CDN_URL } from "../Helpers";

export default function CreateCertificateProgramView({ data, callback }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  const [cover, setCover] = useState(null);

  function getCover(e) {
    // Ensure the event has a file input with a file selected
    if (e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Use FileReader to read the file and generate a URL
      const reader = new FileReader();

      // On load, set the result (URL) to an image src or handle it as needed
      reader.onload = function (event) {
        const fileURL = event.target.result;
        setCover(fileURL); // Replace with your image handling logic
      };

      // Read the file as a Data URL
      reader.readAsDataURL(file);
    } else {
      //..
    }
  }


  // Function to handle form submission
  async function createCertificateProgram() {
    const _cover = document.getElementById("_cover").files[0];

    if (_cover) {
      if (!loading) {
        if (Number(numberOfCourses) > 0) {
          if (title.trim().length > 0) {
            if (description.trim().length > 10) {
              setLoading(true);
              // Call the API to create a new certificate program
              await callApi("create-certificate-program", {
                title,
                description,
                numberOfCourses,
                cover: _cover,
              }).then((response) => {
                if (response.status === 1) {
                  appContext.navBack();
                  appContext.getMyPublisherCertificatePrograms();
                  appContext.tellMessage("Certificate Program was created successfully");
                } else {
                  appContext.tellError(response.msg);
                }
              });
              setLoading(false);
            } else {
              appContext.tellError("Invalid description");
            }
          } else {
            appContext.tellError("Invalid title");
          }
        } else {
          appContext.tellError("Invalid number of courses");
        }
      } else {
        appContext.tellInfo("Please wait....");
      }
    } else {
      appContext.tellError("Invalid cover");
    }
  }

  async function updateCertificateProgram() {
    const _cover = document.getElementById("_cover").files[0];

    if (!loading) {
      if (Number(numberOfCourses) > 0) {
        if (title.trim().length > 0) {
          if (description.trim().length > 10) {
            setLoading(true);
            // Call the API to create a new certificate program
            await callApi("update-certificate-program", {
              id: data?.id,
              title,
              description,
              numberOfCourses,
              cover: (_cover) ? _cover : '',
            }).then((response) => {
              if (response.status === 1) {
                appContext.getMyPublisherCertificatePrograms(); // Assuming you have a method to fetch certificate programs
                appContext.tellMessage("Certificate Program was updated successfully");
                if (callback) {
                  callback();
                }
              } else {
                appContext.tellError(response.msg);
              }
            });
            setLoading(false);
          } else {
            appContext.tellError("Invalid description");
          }
        } else {
          appContext.tellError("Invalid title");
        }
      } else {
        appContext.tellError("Invalid number of courses");
      }

    } else {
      appContext.tellError("Invalid cover");
    }
  }

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
      setCover(CDN_URL + data.cover);
      setNumberOfCourses(data.numberOfCourses);
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading]);



  return (
    <div className="container">
			<div className="mSectionTitle">
				{data ? "Edit this programme" : "Create new certificate program"}
			</div>

			<div className="body">
				<div className="mb-4">
					<label className="form-label">Title</label>
					<input
						className="form-control"
						type="text"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>

				<div className="mb-4">
					<label className="form-label">Number of courses</label>
					<input
						className="form-control"
						type="text"
						value={numberOfCourses}
						onChange={(e) => setNumberOfCourses(e.target.value)}
					/>
				</div>

				<div className="mb-4">
					<label className="form-label">Cover Photo </label>
					{
						(cover) ?
							<img
								width="100%"
								height="auto"
								src={cover}
							/>
							: ""
					}
					<input
						className="form-control"
						type="file"
						accept="image/*"
						id="_cover"
						onChange={(e) => getCover(e)}
					/>
				</div>

				<div className="mb-4">
					<label className="form-label">Description</label>
					<textarea
						className="form-control"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					></textarea>
				</div>

				<div className="mb-4">
					<button
						className="btn btn-padded bg-secondary btn-block"
						onClick={(data) ? updateCertificateProgram : createCertificateProgram}
					>
						Publish
					</button>
				</div>
			</div>
    </div>
  );
}
