import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import OneCourseContent from '../ones/OneCourseContent';
import AddCourseContent from '../views/AddCourseContent';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi("get-course-content-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError("Could not fetch data, check your connection");
      }
    })
  }

  async function publish() {
    setLoading(true);
    await callApi("publish-course-content", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getAllCourseContents();
      } else {
        appContext.tellError("Could not publish, check your connection");
      }
    })
    setLoading(false);
  }

  async function unpublish() {
    setLoading(true)
    await callApi("unpublish-course-content", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage("Successful");
        getData();
        appContext.getAllCourseContents();
      } else {
        appContext.tellError("Could not unpublish, check your connection");
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              {data.title}
            </h6>
          </div>

          <div className="col-md-12">
            <AddCourseContent data={data} callback={getData} />
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}