import { useState, useEffect, useRef } from "react";
import { MdPlayArrow, MdPause, MdForward10, MdReplay10, MdAudioFile } from "react-icons/md";
import { getInlineLoader } from '../Helpers';

export default function AudioPlayer({ title }) {
  const dropboxToken = "sl.CA-iLvhYewNwPOBLvBf61XpE1nM394G3r5s4FBxYNnCbigJFE44xMtSkOT3oCXWzuGqBcCavNfjX8PGqSRkzzO0r0TRgVolP8b6j99hYIVQEu6q0QQzTfKSV80W3-_82ohCJV49WxM4y";  // Add your Dropbox token here
  const filePath = "/AUDIO BOOKS VITABU VYOTE/AUDIO; CHUO CHA MAUZO/CHUO CHA MAUZO_UT& MODULI 01 CONT (2).mp3";  // Path to your audio file in Dropbox
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);  // State for the audio URL
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying(!playing);
  };

  const seekForward = () => {
    audioRef.current.currentTime += 10;
  };

  const seekBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const handleAudioLoaded = () => {
    setLoading(false);
    setReady(true);
  };

  const handleAudioError = () => {
    setLoading(false);
    // Handle error appropriately
  };

  const fetchDropboxUrl = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://api.dropboxapi.com/2/files/get_temporary_link", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${dropboxToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          path: filePath,  // Path of the file in Dropbox
        }),
      });
      const data = await response.json();
      console.log("dropbox response", data);
      
      if (data && data.link) {
        setAudioUrl(data.link);  // Set the audio URL from Dropbox
      } else {
        setLoading(false);
        console.error("Error fetching Dropbox link.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Dropbox link:", error);
    }
  };

  useEffect(() => {
    fetchDropboxUrl();  // Fetch the URL when the component mounts
  }, []);

  return (
    <div className="container">
      {loading ? (
        <div className="mSupportLoading">{getInlineLoader()}</div>
      ) : (
        ready && audioUrl && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body text-center">
                  <MdAudioFile size={90} />
                  <h6
                    className="font-semi-bold mt-4 mb-4"
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    {title}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={seekBackward}>
                <MdReplay10 size={30} />
              </button>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={togglePlayPause}>
                {playing ? <MdPause size={30} /> : <MdPlayArrow size={30} />}
              </button>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={seekForward}>
                <MdForward10 size={30} />
              </button>
            </div>
          </div>
        )
      )}

      <audio
        ref={audioRef}
        src={audioUrl}
        onLoadedData={handleAudioLoaded}
        onError={handleAudioError}
        preload="auto"
      />
    </div>
  );
}
