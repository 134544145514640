export const STR_APP_NAME = {
  en: "Tanzanite Skills Academy",
  sw: "Tanzanite Skills Academy",
};

export const STR_HEALTH = {
  en: "health",
  sw: "afya",
}

export const STR_RELATIONSHIPS = {
  en: "relationships",
  sw: "mahusiano",
}

export const STR_STORIES = {
  en: "stories",
  sw: "masimulizi",
}

export const STR_COURSES = {
  en: "Courses",
  sw: "Kozi",
}


export const STR_GET_STARTED = {
  en: "get started",
  sw: "anza sasa",
}

export const STR_CONTINUE = {
  en: "continue",
  sw: "endelea",
}

export const STR_ENTER = {
  en: "enter",
  sw: "ingia",
}

export const STR_ENTER_YOUR_PHONE_NUMBER = {
  en: "Enter your phone number",
  sw: "Ingiza namba yako ya simu",
}

export const STR_PHONE_NUMBER = {
  en: "Phone number",
  sw: "Namba ya simu",
}

export const STR_ENTER_OTP = {
  en: "Enter the OTP sent to you",
  sw: "Ingiza OTP uliotumiwa",
}

export const STR_PLEASE_WAIT = {
  en: "Please wait..",
  sw: "Tafadhali subiri..",
}

export const STR_INVALID_PHONE = {
  en: "Invalid phone number",
  sw: "Namba ya simu sio sahihi",
}

export const STR_INVALID_OTP = {
  en: "Invalid OTP",
  sw: "OTP sio sahihi",
}

export const STR_WELCOME = {
  en: "Welcome..",
  sw: "Karibu..",
}

export const STR_CHANGE_PHONE = {
  en: "Change Phone",
  sw: "Badili Simu",
}

export const STR_CHANGE = {
  en: "Change Phone",
  sw: "Badili Simu",
}

export const STR_RESEND_OTP = {
  en: "Resend OTP",
  sw: "Tuma tena OTP",
}

export const STR_MENU_HOME = {
  en: "Home",
  sw: "Nyumbani",
}

export const STR_MENU_DATING = {
  en: "Dating",
  sw: "Mahusiano",
}

export const STR_MENU_DOCTORS = {
  en: "Doctors",
  sw: "Madaktari",
}

export const STR_MENU_INBOX = {
  en: "Inbox",
  sw: "Ujumbe",
}

export const STR_MENU_ARTICLES = {
  en: "Articles",
  sw: "Makala",
}

export const STR_MENU_STORIES = {
  en: "Stories",
  sw: "Simulizi",
}

export const STR_MENU_UTILITIES = {
  en: "Utilities",
  sw: "Visaidizi",
}

export const STR_LATEST_ARTICLES = {
  en: "Latest Articles",
  sw: "Makala Mpya",
}

export const STR_MORE_ARTICLES = {
  en: "More Articles",
  sw: "Makala Zaidi",
}

export const STR_LATEST_STORIES = {
  en: "Latest Stories",
  sw: "Simulizi Mpya",
}

export const STR_LATEST_COURSES = {
  en: "Latest Courses",
  sw: "Kozi Mpya",
}

export const STR_MORE_STORIES = {
  en: "More Stories",
  sw: "Simulizi Zaidi",
}

export const STR_MORE_COURSES = {
  en: "More Courses",
  sw: "Kozi Zaidi",
}

export const STR_PERSONAL_SETTINGS = {
  en: "Personal Settings",
  sw: "Mipangilio Binafsi",
}

export const STR_UPDATE_USERNAME = {
  en: "Update username",
  sw: "Badili/Weka jina",
}

export const STR_CHANGE_PHONE_NUMBER = {
  en: "Change phone number",
  sw: "Badili namba ya simu",
}

export const STR_UPDATE_PERSONAL_INFO = {
  en: "Update personal information",
  sw: "Badili/Weka taarifa binafsi",
}

export const STR_PAYMENT_SETTINGS = {
  en: "Payments and Purchases",
  sw: "Malipo na Manunuzi",
}

export const STR_SUBSCRIPTION_FEE_PAYMENTS = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_CONSULTATION_FEE_PAYMENTS = {
  en: "Doctor's consultation fee payment",
  sw: "Malipo ya kuwasiliana na daktari",
}

export const STR_MY_SUBSCRIPTION = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_SUBSCRIPTION_HAS_EXPIRED = {
  en: "Subscription has expired",
  sw: "Kifurushi kimeisha",
}

export const STR_PURCHASE_SUBSCRIPTION_PACKAGE = {
  en: "Purchase a subscription package to view all content in this App",
  sw: "Nunua kifurushi cha matumizi ili kuona kila kitu kilichomo ndani ya App",
}

export const STR_SELECT = {
  en: "Select",
  sw: "Chagua",
}

export const STR_COMPLETE_PAYMENT = {
  en: "Complete Payment",
  sw: "Kamilisha Malipo",
}

export const STR_PAY_WITH_CARD = {
  en: "Pay with Credit Card",
  sw: "Lipia kwa Kadi",
}

export const STR_PAY_WITH_MOBILE = {
  en: "Pay with Mobile Network",
  sw: "Lipia kwa Mtandao wa Simu",
}

export const STR_SELECT_PAYMENT_METHOD = {
  en: "Select payment method",
  sw: "Chagua njia ya kufanya malipo",
}

export const STR_ENTER_PHONE_NUMBER_FOR_PAYMENT = {
  en: "Enter phone number you want to use for this payment. All Tanzanian networks are supported.",
  sw: "Ingiza namba ya simu utakayofanyia malipo. Tunapokea kutoka mitandao yote ya Tanzania",
}

export const STR_FINALIZE_PAYMENT_CARD = {
  en: "Please wait, you'll soon be redirected to complete the payment with your card.",
  sw: "Tafadhali subiri utaelekezwa kwenye kiungo cha kufanyia malipo kwa kadi yako",
}

export const STR_FINALIZE_PAYMENT_MOBILE = {
  en: "Push USSD was sent to your phone please check and finalize the payment",
  sw: "Tafadhali angalia simu yako ili kukamilisha malipo kwa USSD Push",
}

export const STR_BUY_NOW = {
  en: "Buy Now",
  sw: "Nunua Sasa",
}

export const STR_DAYS = {
  en: "Days",
  sw: "Siku",
}

export const STR_FROM = {
  en: "From",
  sw: "Kuanzia",
}

export const STR_TO = {
  en: "To",
  sw: "Hadi",
}

export const STR_PRICE = {
  en: "Price",
  sw: "Bei",
}

export const STR_PASSWORD = {
  en: "Password",
  sw: "Nenosiri",
}

export const STR_CONFIRM = {
  en: "Confirm",
  sw: "Hakiki",
}

export const STR_INVALID_PASSWORD = {
  en: "Invalid Password, password must have atleast 8 characters",
  sw: "Nenosiri angalau liwe na muunganiko wa namba na herufi angalau 8",
}

export const STR_INVALID_CONFIRM_PASSWORD = {
  en: "Please confirm your password correctly",
  sw: "Tafadhali hakiki nenosiri lako kwa usahihi",
}

export const STR_CHANGE_LANGUAGE = {
  en: "Tumia Swahili",
  sw: "Change to English",
}

export const STR_FORGOT_PASSWORD = {
  en: "Forgot Password",
  sw: "Nimesahau Nenosiri",
}

export const STR_NO = {
  en: "No",
  sw: "Hapana",
}

export const STR_YES = {
  en: "Yes",
  sw: "Ndio",
}

export const STR_BOND_CALCULATOR = {
  en: "Bonds Calculator",
  sw: "Kikokotozi cha Hatifungani",
}

export const STR_LOAN_CALCULATOR = {
  en: "Loans Calculator",
  sw: "Kikokotozi cha Mkopo",
}

export const STR_UTT_CALCULATOR = {
  en: "UTT Investment Calculator",
  sw: "Kikokotozi cha uwekezaji wa UTT",
}

export const STR_BOOKS_LIBRARY = {
  en: "Books' Library",
  sw: "Maktaba ya Vitabu",
}

export const STR_NO_COURSE_CONTENT = {
  en: "Content for this course will be published soon",
  sw: "Makala kwenye kozi hii zitawekwa hivi punde",
}

export const STR_TAKE_QUIZ = {
  en: "Take Quiz",
  sw: "Fanya Jaribio",
}

export const STR_SELECT_CORRECT_ANSWER = {
  en: "Select correct answer",
  sw: "Chagua jibu sahihi",
}

export const STR_WRITE_CORRECT_ANSWER = {
  en: "Write correct answer",
  sw: "Andika jibu sahihi",
}

export const STR_SUBMIT = {
  en: "Submit",
  sw: "Wasilisha",
}

export const STR_SUCCESSFUL = {
  en: "Successful....",
  sw: "Imefanikiwa...",
}

export const STR_YOUR_MARKS_WILL_APPEAR_HERE_AFTER_COMPLETING_QUIZ = {
  en: "Your marks will appear here after quiz completion",
  sw: "Alama zako zitaonekana hapa ukimaliza kufanya jaribio",
}

export const STR_YOUR_RESULTS = {
  en: "Your results",
  sw: "Matokeo Yako",
}

export const STR_TAKE_QUIZES_TO_BE_CERTIFIED = {
  en: "Take Quizes to become certified once you complete this course if you score beyond the average passmark",
  sw: "Fanya majaribio na upewe cheti mwisho wa kozi endapo utavuka wastani wa alama zinazotakiwa",
}

export const STR_REQUIRED_AVERAGE_PASSMARK = {
  en: "Required Average Passmark",
  sw: "Wastani wa Alama Unaotakiwa",
}

export const STR_SHOP = {
  en: "Shop",
  sw: "Duka",
}

export const STR_NO_ITEMS_FOUND = {
  en: "No items were found",
  sw: "Hakuna kitu kwa sasa",
}

export const STR_QUANTITY = {
  en: "Quantity",
  sw: "Kiasi",
}

export const STR_PAYMENT_METHOD = {
  en: "Payment Method",
  sw: "Njia ya malipo",
}

export const STR_INVALID_QUANTITY = {
  en: "Invalid Quantity",
  sw: "Kiasi sio sahihi",
}

export const STR_INVALID_PAYMENT_METHOD = {
  en: "Invalid Payment method or Phone number",
  sw: "Njia ya malipo au namba ya simu sio sahihi",
}

export const STR_ORDER_WAS_RECEIVED_WAIT_FOR_FEEDBACK = {
  en: "Your order was received, we'll get back to you soon",
  sw: "Oda yako imepokelewa, tutakurudia hivi punde",
}

export const STR_SHIPPING_ADDRESS = {
  en: "Shipping Address",
  sw: "Anwani/Mahali pa kutuma mzigo",
}

export const STR_INVALID_SHIPPING_ADDRESS = {
  en: "Invalid Shipping Address",
  sw: "Anwani/Mahali pa kutuma mzigo sio sahihi",
}

export const STR_ORDER_NOW = {
  en: "Order Now",
  sw: "Weka Oda Sasa",
}

export const STR_ORDER_NOTES = {
  en: "Would you like to leave any notes on this order? Write here",
  sw: "Je ungependa kuacha maelekezo yoyote? Andika hapa.",
}

export const STR_MY_ORDERS = {
  en: "My Orders",
  sw: "Oda zangu",
}

export const STR_MY_PURCHASES = {
  en: "My Purchases",
  sw: "Manunuzi yangu",
}

export const STR__ORDER_STATUS_INQUIRY = {
  en: "Inquiry",
  sw: "Inasubiri kuhakikiwa",
}

export const STR_ORDER_STATUS_PENDING = {
  en: "Pending payment",
  sw: "Inasubiri malipo",
}

export const STR_ORDER_STATUS_SUCCESS = {
  en: "Completed Payment",
  sw: "Malipo yamekamilika",
}

export const STR_ORDER_STATUS_DELIVERED = {
  en: "Delivered",
  sw: "Imekamilishwa",
}

export const STR_ORDER_STATUS_CANCELLED = {
  en: "Cancelled",
  sw: "Imesitishwa",
}

export const STR_PAY_NOW = {
  en: "Pay Now",
  sw: "Lipia Sasa",
}

export const STR_PENDING_PAYMENT_TEXT_HELP = {
  en: "Payment was not received for this order, you can retry to make payment or contact us if you have already made the payment but still see this message",
  sw: "Malipo ya Oda hii hayajakamilika. Unaweza kurudia kufanya malipo au wasiliana nasi kama uifanya malipo lakini bado unaona ujumbe huu",
}

export const STR_CANCEL_ORDER = {
  en: "Cancel Order",
  sw: "Sitisha Oda",
}

export const STR_NO_RECORDS = {
  en: "No records were found",
  sw: "Hakuna kitu kwa sasa",
}

export const STR_PURCHASED_BOOK = {
  en: "Purchased Books",
  sw: "Vitabu Nilivyonunua",
}

export const STR_PURCHASED_COURSES = {
  en: "Enrolled Courses",
  sw: "Kozi Nilizomo",
}

export const STR_OTHER_PURCHASES = {
  en: "Other Purchases",
  sw: "Manunuzi Mengineyo",
}

export const STR_OPEN = {
  en: "Open",
  sw: "Fungua",
}

export const STR_DOWNLOAD = {
  en: "Download",
  sw: "Pakua",
}

export const STR_CERTIFICATE_PROGRAMS = {
  en: "Certificate Programs",
  sw: "Programu za Vyeti",
}

export const STR_COURSES_IN_THIS_PROGRAM = {
  en: "Courses in this program",
  sw: "Kozi ndani ya programu hii",
}

export const STR_YOU_MUST_TAKE_COURSE_BELOW = {
  en: "You must complete the course below before proceeding with this one",
  sw: "Unatakiwa kumaliza kozi hio hapo chini kabla ya kuendelea na hii",
}

export const STR_COURSE_CERTIFICATES = {
  en: "Short Course Certificates",
  sw: "Vyeti vya kozi fupi",
}

export const STR_PROGRAM_CERTIFICATES = {
  en: "Program Certificates",
  sw: "Vyeti vya program mbalimbali",
}

export const STR_CERTIFICATE = {
  en: "Certificate",
  sw: "Cheti",
}

export const STR_FULLNAME = {
  en: "Fullname",
  sw: "Jina kamili",
}

export const STR_INVALID_FULLNAME = {
  en: "Invalid Fullname",
  sw: "Jina kamili sio sahihi",
}

export const STR_INVALID_BIO = {
  en: "Invalid Bio",
  sw: "Maelezo kuhusu wewe sio sahihi",
}


export const STR_SUCCESS = {
  en: "Successful...",
  sw: "Imefanikiwa...",
}

export const STR_INVALID_USERNAME = {
  en: "Invalid name",
  sw: "Jina sio sahihi",
}

export const STR_PLEASE_UPDATE_FULLNAME_FOR_CERTIFICATES = {
  en: "Please update your fullname as you want it to appear on the certificates",
  sw: "Tafadhali hariri/weka jina lako kamili kama utakavyotaka lionekane kwenye vyeti",
}

export const STR_EDIT_NOW = {
  en: "Edit Now",
  sw: "Hariri Sasa",
}

export const STR_NOTIFICATIONS = {
  en: "Notifications",
  sw: "Taarifa",
}

export const STR_LOGOUT = {
  en: "Log Out",
  sw: "Ondoka kwenye Akaunti",
}

export const STR_SEARCH = {
  en: "Search",
  sw: "Tafuta",
}

export const STR_SEARCH_ARTICLES = {
  en: "Search Articles",
  sw: "Tafuta Makala",
}

export const STR_SEARCH_COURSES = {
  en: "Search Courses",
  sw: "Tafuta Kozi",
}

export const STR_SEARCH_HERE = {
  en: "Search here...",
  sw: "Tafuta hapa....",
}

export const STR_OR_SELECT_CATEGORY = {
  en: "Or just select category",
  sw: "Au chagua aina",
}

export const STR_NO_RESULTS = {
  en: "No results",
  sw: "Hakuna matokeo",
}

export const STR_TOOK_COURSE_BUT_FAILED_TEXT = {
  en: "You took this course but failed to get the required minimum average passmark. Click the button below to clear progress and retake this course",
  sw: "Ulisoma kozi hii lakini haukufanikiwa kupata wastani wa alama zinazotakiwa. Bofya hapo chini kuanza kozi hii upya",
}

export const STR_RETAKE_COURSE = {
  en: "Retake Course",
  sw: "Anza Kusoma Upya",
}

export const STR_BACK_TO_COURSE = {
  en: "Back to Course",
  sw: "Rudi kwenye Kozi",
}

export const STR_USE_EMAIL = {
  en: "Use Email",
  sw: "Barua Pepe",
}

export const STR_USE_PHONE = {
  en: "Use Phone",
  sw: "Namba ya Simu",
}

export const STR_ENTER_EMAIL_ADDRESS = {
  en: "Enter your email address",
  sw: "Weka anwani yako ya barua pepe",
}

export const STR_INVALID_EMAIL = {
  en: "Invalid email",
  sw: "Anwani ya barua pepe sio sahihi",
}

export const STR_LOGIN_TO_CONTINUE = {
  en: "Login to continue",
  sw: "Ingia ili kuendelea",
}

export const STR_LOGIN_NOW = {
  en: "Login now",
  sw: "Ingia Sasa",
}

export const STR_BOOKS = {
  en: "Books",
  sw: "Vitabu",
}

export const STR_OTHER_ITEMS = {
  en: "Other items",
  sw: "Bidhaa nyinginezo",
}

export const STR_OPEN_BOOK = {
  en: "Open Book",
  sw: "Fungua Kitabu",
}

export const STR_PLAY_AUDIO = {
  en: "Play Audio",
  sw: "Fungua Audio",
}

export const STR_VIEW_ALL_BOOKS = {
  en: "View All Books",
  sw: "Ona Vitabu Vyote",
}

export const STR_PDF = {
  en: "PDF Copy",
  sw: "Nakala ya PDF",
}

export const STR_AUDIO = {
  en: "Audio",
  sw: "Audio",
}

export const STR_OPEN_AUDIO = {
  en: "Open Audio",
  sw: "Fungua Audio",
}

export const STR_MENU_ACADEMY = {
  en: "Academy",
  sw: "Mafunzo",
}

export const STR_MENU_GROUPS = {
  en: "Groups",
  sw: "Makundi",
}

export const STR_MENU_JOBS = {
  en: "Jobs",
  sw: "Ajira",
}

export const STR_MENU_ACCOUNT = {
  en: "Account",
  sw: "Akaunti",
}

export const STR_BECOME_TRAINER = {
  en: "Become a publisher",
  sw: "Kuwa Mwandishi",
}

export const STR_BIO = {
  en: "Your Bio (About You)",
  sw: "Maelezo kuhusu wewe",
}

export const STR_SEND_REQUEST = {
  en: "Send Request",
  sw: "Tuma Maombi",
}

export const STR_YOUR_REQUEST_IS_BEING_PROCESSED = {
  en: "We are processing your request, we'll contact you back soon.",
  sw: "Tunashughulikia ombi lako, tutakurudia muda si mrefu",
}

export const STR_ALREADY_TRAINER = {
  en: "You are already a publisher",
  sw: "Tayari wewe ni Mwandishi",
}

export const STR_PUBLISHER_DASHBOARD = {
  en: "Publisher Dashboard",
  sw: "Dashbodi ya Mwandishi",
}

export const STR_NO_GROUPS_WERE_FOUND_JOIN_COURSES = {
  en: "No groups were found, enroll  in courses to be added to groups",
  sw: "Hauna makundi kwa sasa, jiunge na kozi ili uungwe kwenye makundi husika",
}

export const STR_COULD_NOT_FETCH_DATA_CHECK_NET = {
  en: "Could not get data, check your network",
  sw: "Imeshindwa kupakia taarifa, tafadhali angalia mtandao wako",
}

export const STR_GROUP_CHATROOM = {
  en: "Chatroom",
  sw: "Ujumbe",
}

export const STR_GROUP_SHARED_ITEMS = {
  en: "Shared",
  sw: "Vishiriki",
}


export const STR_NO_MESSAGES = {
  en: "No messages",
  sw: "Hakuna ujumbe kwa sasa",
}

export const STR_CLICK_TO_PICK_IMAGE = {
  en: "Click below to select image",
  sw: "Bonyeza hapo chini kuchagua picha",
}

export const STR_SEND = {
  en: "Send",
  sw: "Tuma",
}

export const STR_CANCEL = {
  en: "Cancel",
  sw: "Ghairi",
}

export const STR_POST_JOB = {
  en: "Post Job Opening",
  sw: "Weka Tangazo la Kazi",
}

export const STR_NO_JOBS_WERE_FOUND = {
  en: "No job openings were found",
  sw: "Hakuna matangazo ya kazi kwa sasa",
}

export const STR_MANAGE_EDUCATION_PROFILE = {
  en: "Manage Education Profile",
  sw: "Kuhusu Elimu Yako",
}

export const STR_MANAGE_WORKING_EXPERIENCE = {
  en: "Manage Working Experience",
  sw: "Kuhusu Uzoefu wako wa Kazi",
}

export const STR_MAX_EDUCATION_LEVEL = {
  en: "Highest Education Level",
  sw: "Kiwango cha juu cha Elimu yako",
}

export const STR_PRIMARY_EDU = {
  en: "Primary Education",
  sw: "Elimu ya Msingi",
}

export const STR_SECONDARY_EDU = {
  en: "Secondary Education",
  sw: "Elimu ya Sekondari",
}

export const STR_HIGH_SCHOOL_EDU = {
  en: "Highschool Education",
  sw: "Elimu ya Sekondari ya Juu",
}

export const STR_CERTIFICATE_EDU = {
  en: "College Certificate",
  sw: "Chuo ngazi ya Cheti",
}

export const STR_DIPLOMA_EDU = {
  en: "College Diploma",
  sw: "Chuo ngazi ya Diploma",
}

export const STR_DEGREE_EDU = {
  en: "Degree",
  sw: "Digrii",
}

export const STR_MASTERS_EDU = {
  en: "Masters",
  sw: "Masters",
}

export const STR_CERTIFICATES_EARNED = {
  en: "Certifications",
  sw: "Vyeti ulivyopata",
}

export const STR_PROGRAM_NAME = {
  en: "Program or Course Name",
  sw: "Jina la Program/Kozi",
}

export const STR_YEAR = {
  en: "Year",
  sw: "Mwaka",
}

export const STR_FILE = {
  en: "File",
  sw: "Faili",
}

export const STR_DELETE = {
  en: "Delete",
  sw: "Futa",
}

export const STR_ADD = {
  en: "Add",
  sw: "Ongeza",
}

export const STR_INVALID_CERTIFICATE_BLOCK = {
  en: "Each certificate must have a name, year and file",
  sw: "Weka jina, faili na mwaka kwa kila cheti",
}

export const STR_SERVER_ERROR = {
  en: "Network error, check your network",
  sw: "Tatizo la kimtandao, hakikisha mtandao wako tafadhali",
}

export const STR_MIN_EDUCATION_LEVEL = {
  en: "Minimum Education Level",
  sw: "Kiwango cha chini cha elimu",
}

export const STR_JOB_OPENING = {
  en: "Job Opening",
  sw: "Tangazo la Ajira",
}

export const STR_DESCRIPTION = {
  en: "Description",
  sw: "Maelezo",
}

export const STR_REQUIRED_SKILLS = {
  en: "Required Skills",
  sw: "Ujuzi Unaohitajika",
}

export const STR_JOB_APPLICANTS = {
  en: "Applicants",
  sw: "Waombaji",
}

export const STR_JOB_MATCHES = {
  en: "Matches",
  sw: "Wanaokidhi",
}

export const STR_DONT_MATCH_FOR_JOB = {
  en: "You do not match for this job due to missing some skills or educational requirement",
  sw: "Hauna vigezo vinavyohitajika kwenye hii kazi",
}

export const STR_YOU_MATCH_FOR_THIS_JOB = {
  en: "Congrats, you match for this job!",
  sw: "Hongera, umekidhi vigezo vya kazi hii",
}

export const STR_APPLY_NOW = {
  en: "Apply Now",
  sw: "Tuma Maombi",
}

export const STR_YOU_APPLIED_FOR_THIS_JOB = {
  en: "You applied for this job",
  sw: "Umetuma maombi ya kazi hii",
}

export const STR_SKILLS = {
  en: "Skills",
  sw: "Ujuzi/Maarifa",
}

export const STR_EMAIL = {
  en: "Email Address",
  sw: "Anwani ya barua pepe",
}

export const STR_EDUCATION_PROFILE = {
  en: "Education Profile",
  sw: "Wasifu wa elimu",
}

export const STR_SKILLS_ACQUIRED_IN_ACADEMY = {
  en: "Skills Acquired at Tanzanite Skills Academy",
  sw: "Ujuzi/Maarifa aliopata kutoka Tanzaniate Academy",
}

export const STR_OTHER_CERTIFICATION = {
  en: "Other Certification",
  sw: "Vyeti Vinginevyo",
}

export const STR_JOB = {
  en: "Job",
  sw: "Kazi",
}

export const STR_WALLET_BALANCE = {
  en: "Wallet Balance (TZS)",
  sw: "Salio (TZS)",
}

export const STR_ENROLL_TEXT = {
  en: "Enroll in this course to connect with the trainer and other learners",
  sw: "Jiunge kwenye hii kozi ili kuungana na mkufunzi pamoja na wanafunzi wengine",
}

export const STR_ENROLL_NOW = {
  en: "Enroll Now",
  sw: "Jiunge na Kozi",
}

export const STR_BOOK_SHOP = {
  en: "Shop",
  sw: "Duka",
}

export const STR_MY_BOOKS = {
  en: "My Books",
  sw: "Vitabu Vyangu",
}

export const STR_SAVED_BOOKS = {
  en: "Saved Books",
  sw: "Vitabu Nilivopakua",
}

export const STR_ = {
  en: "",
  sw: "",
}