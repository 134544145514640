import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten, remove255 } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import ImageSlider from "../ones/ImageSlider";
import { STR_BUY_NOW, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_GROUP_CHATROOM, STR_GROUP_SHARED_ITEMS, STR_INVALID_PAYMENT_METHOD, STR_INVALID_QUANTITY, STR_INVALID_SHIPPING_ADDRESS, STR_ORDER_NOTES, STR_ORDER_NOW, STR_ORDER_WAS_RECEIVED_WAIT_FOR_FEEDBACK, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PAYMENT_METHOD, STR_PHONE_NUMBER, STR_PRICE, STR_QUANTITY, STR_SHIPPING_ADDRESS } from "../Strings";
import MobileInterface from "../MobileInterface";
import { MdCheckCircle } from "react-icons/md";
import Login from "./Login";
import TabbedView from "../components/TabbedView";
import GroupChatroom from "../views/GroupChatroom";
import GroupSharedItems from "../views/GroupSharedItems";


export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  return <GroupChatroom groupId={id} />

	/*
  return (
		<TabbedView
			tabs={
				[
					{ id: 0, label: STR_GROUP_CHATROOM[appContext.language], content: <GroupChatroom groupId={id} /> },
					{ id: 1, label: STR_GROUP_SHARED_ITEMS[appContext.language], content: <GroupSharedItems  groupId={id} /> },
				]
			}
		/>
	)
	*/

}