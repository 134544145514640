import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { MdPerson } from "react-icons/md";
import Login from "./Login";
import { STR_CERTIFICATE, STR_DEGREE_EDU, STR_DESCRIPTION, STR_DIPLOMA_EDU, STR_HIGH_SCHOOL_EDU, STR_JOB_OPENING, STR_MASTERS_EDU, STR_MIN_EDUCATION_LEVEL, STR_NO_RECORDS, STR_PRIMARY_EDU, STR_REQUIRED_SKILLS, STR_SECONDARY_EDU } from "../Strings";
import OneJobApplication from '../ones/OneJobApplication';

export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);


	async function init() {
		//run initializations here
		setReady(false);
		await getData();
		setReady(true);
	}

	async function getData() {
		await callApi("get-job-applicants", { id }).then(response => {
			if (response.status === 1) {
				setData(response.data);
			}
		})
	}

	useEffect(() => {
		if(data) {

		}
	}, [ data ])

	useEffect(() => {
		init();
	}, [id])



	if (ready && data) {
		
		return (
			<div className="container" style={{ paddingTop: "20px" }}>
				{
					(data && data.length > 0) ?
					data.map((item, i) => {
						return (
							<OneJobApplication
								key={i}
								data={item}
							/>
						)
					})
					:
					<h6
					>
						{STR_NO_RECORDS[appContext.language]}
					</h6>
				}
			</div>
		)

	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}



}