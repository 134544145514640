import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { callApi, CDN_URL, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';
import { MdAdb, MdAdd, MdClose, MdDelete } from "react-icons/md";

export default function View({  }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(10)
  const [unit, setUnit] = useState("");
  const [itemType, setItemType] = useState("digital");
  const [unitPrice, setUnitPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [opSuccessful, setOpSuccesful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [itemCategory, setItemCategory] = useState('book');
  const [audioFiles, setAudioFiles] = useState([]);
  const [skills, setSkills] = useState([]);

  async function init() {
    setReady(false);
    //...inits
    setReady(true);
  }

  function getAudioFileTemp() {
    return {
      title: "",
    }
  }

  function handleAudioFilesChange(index, field, value) {
    setAudioFiles(_prev => {
      const _new = [..._prev];
      _new[index][field] = value;

      return _new;
    })
  }

  function deleteAudioFile(index) {
    setAudioFiles(_prev => {
      const _new = [..._prev];
      _new.splice(index, 1);

      return _new;
    })
  }

  function addAudioFile() {
    setAudioFiles(_prev => {
      const _new = [..._prev];
      _new.push(getAudioFileTemp());

      return _new;
    })
  }

  function addSkill() {
		setSkills(_prev => {
			const _new = [..._prev];
			_new.push("");

			return _new;
		})
	}

	function deleteSkill(_index) {
		setSkills(_prev => {
			const _new = [..._prev];
			_new.splice(_index, 1);

			return _new;
		})
	}

	function updateSkill(_index, _value) {
		setSkills(_prev => {
			const _new = [..._prev];
			_new[_index] = _value;

			return _new;
		})
	}

	function cleanSkills(_skills) {
		// Trim and filter empty items
		const _cleaned = _skills
			.map(item => item.trim()) // Trim whitespace
			.filter(item => item.length > 0); // Remove empty items

		// Use a Set to remove duplicates and convert back to an array
		return [...new Set(_cleaned)];
	}

  async function postItem() {
    const _img1 = document.getElementById('_img1').files[0] || "";
    const _img2 = document.getElementById('_img2').files[0] || "";
    const _img3 = document.getElementById('_img3').files[0] || "";
    let itemFile = "";
    let itemAudioFile = "";
    try {
      itemFile = document.getElementById('_itemFile').files[0];
      itemAudioFile = document.getElementById('_itemAudioFile').files[0];
    } catch (error) {
      //..
    }

    if (!loading) {
      //get audio files (if any)
      const audioFilesToUpload = {};

      if (audioFiles && audioFiles.length > 0) {
        for (let i = 0; i < audioFiles.length; i++) {
          const item = audioFiles[i];
          const file = document.getElementById("_audioFile" + i).files[0];
          audioFilesToUpload["_audioFile" + i] = file;
        }
      }
      if (title.trim().length > 0) {
        if (description.trim().length > 0) {
          if (!isNaN(unitPrice) && Number(unitPrice) > 0) {
            if (
              (itemType === 'digital' && (itemFile || itemAudioFile)) ||
              (itemType === 'digital_physical' && (itemFile || itemAudioFile)) ||
              itemType === 'physical'
            ) {
              if (img1 || img2 || img3) {
                setLoading(true);
                await callApi(
                  "post-shop-item",
                  {
                    title,
                    description,
                    unit,
                    unitPrice,
                    availableQuantity,
                    itemType,
                    itemCategory,
                    shippingCost,
                    itemFile,
                    itemAudioFiles: JSON.stringify(audioFiles),
                    ...audioFilesToUpload,
                    img1: _img1,
                    img2: _img2,
                    img3: _img3,
                    skills: JSON.stringify(cleanSkills(skills)),
                  }
                ).then(response => {
                  if (response.status === 1) {
                    appContext.tellMessage("Success");
                    appContext.getMyPublisherBooks();
                    appContext.navBack();
                  } else {
                    appContext.tellError(response.msg);
                  }
                })
                setLoading(false);
              } else {
                appContext.tellError("At least one image is required");
              }
            } else {
              appContext.tellError("Invalid Item type or Item file");
            }
          } else {
            appContext.tellError("Invalid unit price");
          }
        } else {
          appContext.tellError("Invalid description");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellInfo("Please wait....");
    }
  }

  function getImg(e) {
    // Ensure the event has a file input with a file selected
    if (e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Use FileReader to read the file and generate a URL
      const reader = new FileReader();

      // On load, set the result (URL) to an image src or handle it as needed
      reader.onload = function (event) {
        const fileURL = event.target.result;
        if (e.target.id === '_img1') {
          setImg1(fileURL)
        } else if (e.target.id === '_img2') {
          setImg2(fileURL)
        } else if (e.target.id === '_img3') {
          setImg3(fileURL)
        }
      };

      // Read the file as a Data URL
      reader.readAsDataURL(file);
    } else {
      //..
    }
  }


  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (
      <div
        className="container"
      >
        <div className="mFormType1">
          <div className="section-title">
            Post Item to Shop
          </div>

          <div className="body">

            <div className="row">

              <div className="mb-4 col-12">
                <label className="form-label">Title*</label>
                <input
                  className="form-control"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Skills</label>
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid black",
                    borderRadius: "10px",
                  }}
                >
                  {
                    skills.map((item, i) => {
                      return (
                        <div className="d-flex mb-1" key={i}>
                          <select
                            key={i}
                            className="form-control align-sel-center"
                            value={item}
                            onChange={(e) => {
                              updateSkill(i, e.target.value)
                            }}
                            style={{
                              flexGrow: "1",
      
                              fontSize: "14px",
                            }}
                          >
                            <option value="">Select Skill</option>
                            {
                              appContext.allSkills?.map((item, i) => {
                                return (
                                  <option key={i} value={item.id}>{item.title}</option>
                                )
                              })
                            }
                          </select>
      
                          <MdDelete
                            size={24}
                            style={{
                              flexShrink: "0",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteSkill(i)}
                            className="ml-2 text-danger align-self-center"
                          />
                        </div>
                      )
                    })
                  }
                  <button
                    className="btn btn-sm btn-dark"
                    onClick={addSkill}
                  >
                    Add
                  </button>
                </div>
              </div>


              <div className="mb-4 col-12" style={{ display: "none" }}>
                <label className="form-label">Item Type*</label>
                <select
                  className="form-control"
                  type="text"
                  value={itemType}
                  onChange={(e) => setItemType(e.target.value)}
                >
                  <option value="digital">Digital</option>
                  <option value="physical">Physical</option>
                  <option value="digital_physical">Digital and Physical</option>
                </select>
              </div>

              <div className="mb-4 col-12" style={{ display: "none" }}>
                <label className="form-label">Item Category*</label>
                <select
                  className="form-control"
                  type="text"
                  value={itemCategory}
                  onChange={(e) => setItemCategory(e.target.value)}
                >
                  <option value="book">Book</option>
                  <option value="others">Others</option>
                </select>
              </div>

              <div className="mb-4 col-12" style={{ display: "none" }}>
                <label className="form-label">Unit</label>
                <input
                  className="form-control"
                  type="text"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                />
              </div>

              <div className="mb-4 col-12">
                <label className="form-label">Unit Price in Tsh*</label>
                <input
                  className="form-control"
                  type="text"
                  value={unitPrice}
                  onChange={(e) => setUnitPrice(e.target.value)}
                />
              </div>



              {
                (itemType === 'physical') ?
                  <>
                    <div className="mb-4 col-12">
                      <label className="form-label">Available Quantity</label>
                      <input
                        className="form-control"
                        type="text"
                        value={availableQuantity}
                        onChange={(e) => setAvailableQuantity(e.target.value)}
                      />
                    </div>
                    {/* 
                    <div className="mb-4 col-6">
                      <label className="form-label">Shipping Cost</label>
                      <input
                        className="form-control"
                        type="text"
                        value={shippingCost}
                        onChange={(e) => setShippingCost(e.target.value)}
                      />
                    </div>
                    */}
                  </>
                  : ""
              }

              {
                (itemType === 'digital' || itemType === 'digital_physical') ?
                  <>
                    <div className="mb-4 col-12">
                      <label className="form-label">PDF File</label>
                      <input
                        className="form-control"
                        type="file"
                        id="_itemFile"
                        accept=".pdf"
                      />
                    </div>
                    <div className="mb-4 col-12">
                      <label className="form-label">Audio Files</label>
                      {
                        (audioFiles && audioFiles.length > 0) ?
                          audioFiles.map((item, i) => {
                            return (
                              <div key={i} className="row">
                                <div className="col-5">
                                  <label className="form-label">Title</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={item.title || ""}
                                    onChange={(e) => {
                                      handleAudioFilesChange(i, 'title', e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="form-label">File</label>
                                  <input
                                    className="form-control"
                                    type="file"
                                    id={"_audioFile" + i}
                                    accept=".mp3"
                                  />
                                </div>
                                <div className="col-2 text-start">
                                  <label className="form-label d-block">Delete</label>
                                  <MdDelete
                                    size={32}
                                    onClick={() => {
                                      deleteAudioFile(i)
                                    }}
                                    className="d-block text-danger"
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })
                          : ""
                      }

                      <div className="text-end mt-2 mb-2">
                        <button onClick={addAudioFile} className="btn btn-dark btn-sm">
                          <MdAdd size={24} />
                        </button>
                      </div>
                    </div>
                  </>
                  : ""
              }

              <div className="mb-4 col-12">
                <label className="form-label">Description*</label>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>

              <div className="mb-4 col-6">
                <label className="form-label">Image 1</label>
                {
                  (img1) ?
                    <img
                      width="100%"
                      height="auto"
                      src={img1}
                    />
                    : ""
                }
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="_img1"
                  onChange={(e) => getImg(e)}
                />
              </div>

              <div className="mb-4 col-6">
                <label className="form-label">Image 2</label>
                {
                  (img1) ?
                    <img
                      width="100%"
                      height="auto"
                      src={img2}
                    />
                    : ""
                }
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="_img2"
                  onChange={(e) => getImg(e)}
                />
              </div>

              <div className="mb-4 col-6">
                <label className="form-label">Image 3</label>
                {
                  (img1) ?
                    <img
                      width="100%"
                      height="auto"
                      src={img3}
                    />
                    : ""
                }
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="_img3"
                  onChange={(e) => getImg(e)}
                />
              </div>



              <div className="mb-4 col-12">
                <button
                  className="btn btn-padded btn-primary btn-block btn-rounded"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "This item will be listed on the shop, continue?",
                      onConfirm: postItem
                    })
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    )
  }

}
