import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdChevronLeft, MdChevronRight, MdPodcasts, MdSkipPrevious, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helpers';
import logo from '../assets/logo.png';
import { Document, Page, pdfjs } from "react-pdf";
import ttc from '../assets/ttc.pdf';


export default function PdfReader({ url }) {

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const appContext = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageScaled, setPageScaled] = useState(false);
  let resizeObserver = null;

  const scalePage = (add = true) => {
    setPageScaled(true);
    setPageWidth(prevWidth => {
      let result = 100;
      if (add) {
        result = prevWidth + 100
      } else {
        result = prevWidth - 100;
      }

      if (result >= 100 && result <= 3000) {
        return result;
      } else {
        appContext.tellError("No more zoom");
        return prevWidth;
      }
    })
  }

  const resetPageWidth = () => {
    try {
      setPageWidth(document.getElementById('_pageReader').clientWidth);
      setPageScaled(false);
    } catch (error) {
      
    }
  }



  const docLoaded = ({ numPages }) => {
    try {
      setNumPages(numPages);
      setPageNumber(1);
      setPageWidth(document.getElementById('_pageReader').clientWidth);
      setLoading(false);
    } catch (error) {
      
    }
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => {
      if ((prevPageNumber + offset) >= 1 && (prevPageNumber + offset) <= numPages) {
        return (prevPageNumber + offset)
      }
      else {
        if (pageNumber > 1) {
          appContext.tellError('You reached the last page');
          appContext.tellInfo('Opening first page..');
        }
        return 1;
      }
    });
    scrollTop();
  }

  function previousPage() {
    changePage(-1);
    scrollTop();
  }

  function nextPage() {
    changePage(1);
    scrollTop();
  }

  function exitReader() {
    //..exit reader
  }

  function goTo(pg) {
    pg = Number(pg);
    if (pg <= numPages && pg > 0) {
      setPageNumber(Number(pg));
    } else {
      appContext.tellError('Invalid page number');
    }
  }

  function scrollTop() {
    const div = document.getElementById('_pageReader');
    div.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const readerContext = {
    scalePage,
    resetPageWidth,
    pageNumber,
    numPages,
    previousPage,
    nextPage,
    goTo,
    exitReader,
  }

  const init = () => {
    setReady(false);
    if (url) {
      setReady(true);

      let menuHidder = (event) => {
        event.preventDefault();
        //props.loaderCallback(false);
      }

      document.addEventListener("contextmenu", menuHidder);
      setLoading(true);

      return () => {
        document.removeEventListener("contextmenu", menuHidder);
        appContext.setShowOverlayLoader(false); //force quit loader
      }
    } else {
      appContext.tellError('Could not open this book');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      finishers();
    }
  }, []);

  useEffect(() => {
    let reader = document.getElementById('_pageReader');
    if (!resizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        try {
          setPageWidth(document.getElementById('_pageReader').clientWidth);
        } catch (error) {
          
        }
      })
    }

    if (pageScaled) {
      resizeObserver.unobserve(reader);

    } else {
      resizeObserver.observe(reader);
    }

    return () => {
      resizeObserver.unobserve(reader);
    }

  }, [pageScaled])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading]);

  useEffect(() => {
    //..
  }, [numPages, ready, pageNumber]);

  return (
    <div
      id="_pageReader"
      style={{
        paddingBottom: "var(--topBarHeight)",
      }}
    >
      {
        (ready) ?
          <>
            <Document loading="Loading PDF, please wait" error="Loading PDF, please wait" file={url} onLoadSuccess={docLoaded}>
              <Page
                width={pageWidth}
                pageNumber={pageNumber}
                renderTextLayer={false}  /* Disables the text layer */
                renderAnnotationLayer={false}  /* Disables annotation layer */
              />
            </Document>
            <div className="PdfReaderBar">
              <div className="container d-flex" style={{ height: "100%" }}>
                <button
                  className="btn btn-sm btn-primary align-self-center"
                  style={{
                    padding: "2px",
                    flexShrink: "0",
                  }}
                >
                  <MdChevronLeft onClick={previousPage} size={30} className="" />
                </button>

                <h6
                  className="text-center align-self-center font-bold"
                  style={{
                    padding: "5px",
                    paddingLeft: "10",
                    paddingRight: "10",
                    fontSize: "14px",
                    margin: "0",
                    minWidth: "80px",
                    flexShrink: "0",
                  }}
                >
                  <input
                    type="number"
                    value={pageNumber}
                    onChange={(e) => {
                      const newPage = e.target.value;
                      if (newPage > 0 && newPage <= numPages) {
                        goTo(newPage); // Call the goTo function with the new page number
                      }
                    }}
                    style={{
                      width: "50px",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  &nbsp; /&nbsp; {numPages}
                </h6>

                <button
                  className="btn btn-sm btn-primary align-self-center"
                  style={{
                    padding: "2px",
                    flexShrink: "0",
                  }}
                >
                  <MdChevronRight onClick={nextPage} size={30} className="" />
                </button>

                <div
                  className="alin-self-center d-flex justify-content-end"
                  style={{
                    height: "100%",
                    flexGrow: "1",
                  }}
                >
                  <MdZoomIn
                    className="align-self-center m-2"
                    size={30}
                    onClick={() => scalePage(true)}
                  />

                  <MdZoomOut
                    className="align-self-center m-2"
                    size={30}
                    onClick={() => scalePage(false)}
                  />
                </div>
              </div>
            </div>
          </>
          :
          <div className="row">
            <div className="col-md-12 support-loading">
              {getInlineLoader()}
            </div>
          </div>
      }
    </div>
  )

}