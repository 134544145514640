import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountHome from "../views/AccountHome";
import MyShoppingOrders from "../views/MyShoppingOrders";
import MyPurchases from "../views/MyPurchases";
import MyDigitalPurchases from "../views/DigitalPurchases";
import SavedBooks from "../views/SavedBooks";
import MyPhysicalPurchases from "../views/PhysicalPurchases";
import CourseCertificates from "../views/CourseCertificates";
import ProgramCertificates from "../views/ProgramCertificates";
import UpdatePersonalInfo from '../views/UpdatePersonalInfo';
import Notifications from '../views/Notifications';
import Login from "../views/Login";
import BecomeTrainer from '../views/BecomeTrainer';
import PublisherDashboard from '../views/PublisherDashboard';
import PublisherCertificatePrograms from '../views/PublisherCertificatePrograms';
import PublisherCourses from '../views/PublisherCourses';
import PublisherArticles from '../views/PublisherArticles';
import PublisherBooks from '../views/PublisherBooks';
import EnrolledCourses from '../views/EnrolledCourses';

export default function Account(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (appContext.auth && appContext.currentUserData) {
      if (
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <AccountHome />
      } else if (
        appContext.navSubItem === 'my-shopping-orders' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyShoppingOrders />
      } else if (
        appContext.navSubItem === 'my-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPurchases />
      } else if (
        appContext.navSubItem === 'my-digital-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyDigitalPurchases />
      } else if (
        appContext.navSubItem === 'my-physical-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPhysicalPurchases />
      } else if (
        appContext.navSubItem === 'course-certificates' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <CourseCertificates />
      } else if (
        appContext.navSubItem === 'program-certificates' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <ProgramCertificates />
      } else if (
        appContext.navSubItem === 'update-personal-info' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <UpdatePersonalInfo />
      } else if (
        appContext.navSubItem === 'notifications' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Notifications />
      } else if (
        appContext.navSubItem === 'become-trainer' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <BecomeTrainer />
      } else if (
        appContext.navSubItem === 'publisher-dashboard' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PublisherDashboard/>
      } else if (
        appContext.navSubItem === 'publisher-certificate-programs' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PublisherCertificatePrograms/>
      } else if (
        appContext.navSubItem === 'publisher-courses' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PublisherCourses/>
      } else if (
        appContext.navSubItem === 'publisher-articles' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PublisherArticles/>
      } else if (
        appContext.navSubItem === 'publisher-books' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PublisherBooks/>
      } else if (
        appContext.navSubItem === 'enrolled-courses' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <EnrolledCourses/>
      } else if (
        appContext.navSubItem === 'saved-books' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <SavedBooks/>
      }
    } else {
      return <Login />
    }


    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}