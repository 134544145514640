import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { formatMoney, getInlineLoader, setCookie } from "../Helpers";
import {
  STR_BECOME_TRAINER,
  STR_BOOKS,
  STR_CERTIFICATE_PROGRAMS,
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_COURSE_CERTIFICATES,
  STR_COURSES,
  STR_LOGOUT,
  STR_MENU_ARTICLES,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NOTIFICATIONS,
  STR_OTHER_PURCHASES,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PROGRAM_CERTIFICATES,
  STR_PUBLISHER_DASHBOARD,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdSearch, MdList, MdLogout, MdNotifications, MdOutlineNotifications, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart, MdTranslate } from 'react-icons/md';
import { GrCertificate } from "react-icons/gr";
import { TbNotification } from "react-icons/tb";
import { GiTeacher } from "react-icons/gi";
import BecomeTrainer from '../views/BecomeTrainer';
import { formatMoneyShorten } from "../Helpers";
import OneArticlePb from '../ones/OneArticlePb';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [ publishedArticles, setPublishedArticles ] = useState([]);
  const [ unpublishedArticles, setUnpublishedArticles ] = useState([]);
  const [ articlesList, setArticlesList ] = useState([]);
  const [ filter, setFilter ] = useState("");
  const [ status, setStatus ] = useState("published");

  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      appContext.getCurrentUserData(),
      appContext.getMyPublisherArticles(),
    ])
    setReady(true);
  }

  function groupArticles() {
    const _published = [];
    const _unpublished = [];

    if(appContext.myPublisherArticles && appContext.myPublisherArticles.length > 0) {
      for (const _article of appContext.myPublisherArticles) {
      
        if(_article.status === 'published') {
          _published.push(_article);
        } else if(_article.status === 'unpublished') {
          _unpublished.push(_article);
        }
      }
    }

    setPublishedArticles(_published);
    setUnpublishedArticles(_unpublished);

    if(status === 'published') {
      setArticlesList(_published);
    } else if(status === 'unpublished') {
      setArticlesList(_unpublished);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupArticles();

  }, [appContext.myPublisherArticles, status])

  if (ready) {
    if(appContext.currentUserData.isPublisher === 'yes') {
      return (
				<div 
					className="container"
				>
					<div className="row">
						<div className="col-md-12">
							<h6 className="main-section-title">
								Articles
							</h6>
						</div>
	
						<div className="col-6">
							<div className="card">
								<div className="card-body">
									<h6
										style={{
											fontSize: "32px"
										}}
									>
										{formatMoneyShorten(publishedArticles.length)}
										<span
											style={{
												display: "block",
												fontSize: "12px",
											}}
										>
											Published
										</span>
									</h6>
								</div>
							</div>
						</div>
	
						<div className="col-6">
							<div className="card">
								<div className="card-body">
									<h6
										style={{
											fontSize: "32px"
										}}
									>
										{formatMoneyShorten(unpublishedArticles.length)}
										<span
											style={{
												display: "block",
												fontSize: "12px",
											}}
										>
											Unpublished
										</span>
									</h6>
								</div>
							</div>
						</div>
	
						<div className="col-md-12 text-end">
							<button
								className="btn btn-primary btn-sm btn-rounded"
								onClick={() => {
									appContext.navTo({
										item: 'view',
										subItem: 'create-article',
									})
								}}
							>
								Create New
							</button>
						</div>
	
						<div className="col-md-12 mb-2 mt-4 pt-4" style={{ borderTop: "3px solid var(--surfaceColor)" }}>
	
							<div className="d-flex justify-content-between mb-4">
								<div 
									className="d-flex align-self-center"
									style={{
										width: "180px",
										margin: 0,
									}}
								>
									<MdSearch size={24} style={{ flexShrink: "0" }} className="mNoMargin align-self-center"/>
									<input
										type="text"
										className="form-control mNoMargin align-self-center"
										style={{
											flexGrow: "1",
										}}
										value={filter}
										onChange={(e) => setFilter(e.target.value)}
									/>
								</div>
	
								<div 
									className="align-self-center"
									style={{
										width: "120px",
										margin: 0,
									}}
								>
									<select
										type="text"
										className="form-control mNoMargin font-semi-bold"
										style={{
											fontSize: "12px",
										}}
										value={status}
										onChange={(e) => setStatus(e.target.value)}
									>
										<option value="published">Published</option>
										<option value="unpublished">Unpublished</option>
									</select>
								</div>
							</div>
	
							{
								(articlesList && articlesList.length > 0) ?
								articlesList.map((item, i) => {
									if(filter && filter.length > 0) {
										if(String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
											return <OneArticlePb key={i} data={item}/>
										} else {
											return null;
										}
									} else {
										return <OneArticlePb key={i} data={item}/>
									}
									
								})
								:
								<h6
									style={{
										fontSize: "14px",
									}}
									className=""
								>
									No articles were found
								</h6>
							}
	
						</div>
		
		
					</div>
		
				</div>
			)
    } else {
      return <BecomeTrainer/>
    }
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}