import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { arrayToString, callApi, CDN_URL, formatDate, formatMoney, stringToArray } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';
import { MdDelete } from "react-icons/md";

export default function View({ courseId, data, callback }) {

  const appContext = useContext(AppContext);
  const editorRef = useRef(null);


  /* Place states here */
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [cover, setCover] = useState(null);
  const [tags, setTags] = useState("");
  const [isQuizMandatory, setIsQuizMandatory] = useState("yes");
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opSuccessful, setOpSuccesful] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);


  function handleEditorChange(_content, _editor) {
    setContent(_content);

  }

  function getQuizQuestionTemplate() {
    return {
      question: "",
      answerType: "simple", //simple or range
      answer: "",
      answerMin: 0,
      answerMax: 0,
      multipleChoices: "",
    }
  }

  function addQuestion() {
    setQuiz(_prev => {
      const _new = [..._prev, getQuizQuestionTemplate()];

      return _new;
    })
  }

  function deleteQuestion(_index) {
    setQuiz(_prev => {
      const _new = [..._prev];
      _new.splice(_index, 1);

      return _new;
    })
  }

  function handleQuestionFieldChange(_index, _field, _value) {
    setQuiz(_prev => {
      const _new = [..._prev];
      _new[_index][_field] = _value;

      return _new;
    })
  }

  function getCover(e) {
    // Ensure the event has a file input with a file selected
    if (e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Use FileReader to read the file and generate a URL
      const reader = new FileReader();

      // On load, set the result (URL) to an image src or handle it as needed
      reader.onload = function (event) {
        const fileURL = event.target.result;
        setCover(fileURL); // Replace with your image handling logic
      };

      // Read the file as a Data URL
      reader.readAsDataURL(file);
    } else {
      //..
    }
  }

  // Function to handle image uploads
  async function imageUploadHandler(blobInfo) {

    return new Promise(async (resolve, reject) => {
      await callApi("upload-attachment", { file: blobInfo.blob() }).then(response => {
        if (response.status === 1) {
          resolve(CDN_URL + response.files[0]);
        } else {
          appContext.tellError("Could not upload attachment");
          reject("Could not upload attachment");
        }
      })
    })

  };

  // Function to handle file picker for images and media
  async function filePickerCallback(callback, value, meta) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : 'video/*');

    input.onchange = async function () {
      const file = this.files[0];
      const formData = new FormData();
      formData.append('file', file);

      await callApi("upload-attachment", { file }).then(response => {
        if (response.status === 1) {
          callback(CDN_URL + response.files[0]);
        } else {
          appContext.tellError("Could not upload attachment");
        }
      })
    };

    input.click();
  };

  async function updateArticle() {
    if (!loading) {
      const _cover = document.getElementById("_cover").files[0];


      if (title.trim().length > 0) {
        if (content.trim().length > 10) {
          setLoading(true);
          await callApi('update-course-content', { id: data?.id, cover: (_cover) ? _cover : '', title, content, tags: JSON.stringify(stringToArray(tags)), quiz: JSON.stringify(quiz) }).then((response) => {
            if (response.status === 1) {
              if (callback) {
                callback();
              }
              appContext.tellMessage("Content was updated successfully");
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError("Invalid content body");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellInfo("Please wait....");
    }
  }

  async function createArticle() {
    if (!loading) {
      const _cover = document.getElementById("_cover").files[0];

      if (title.trim().length > 0) {
        if (content.trim().length > 10) {
          if (_cover) {
            if ((isQuizMandatory === 'yes' && quiz.length > 0) || isQuizMandatory === 'no') {
              setLoading(true);
              await callApi('add-course-content', { courseId, cover: _cover, title, content, tags: JSON.stringify(stringToArray(tags)), isQuizMandatory, quiz: JSON.stringify(quiz) }).then((response) => {
                if (response.status === 1) {
                  appContext.navBack();
                  appContext.tellMessage("Content was added successfully");
                } else {
                  appContext.tellError(response.msg);
                }
              })
              setLoading(false);
            } else {
              appContext.tellError("Invalid quiz");
            }

          } else {
            appContext.tellError("Invalid cover image");
          }
        } else {
          appContext.tellError("Invalid content body");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellInfo("Please wait....");
    }
  }

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setContent(data.content);
      setCover(CDN_URL + data.cover);
      setTags(arrayToString(JSON.parse(data.tags)));
      setIsQuizMandatory(data.isQuizMandatory);
      setQuiz(JSON.parse(data.quiz));

      if(isEditorReady) {
				if (editorRef.current && editorRef.current.editor) {
					editorRef.current.editor.setContent(data.content);
				}
			}
    }
  }, [data, isEditorReady])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  return (
    <div
      className="container"
    >
      <div className="mFormType1">
        <div className="section-title">
          {data ? "Edit" : "Create new"}
        </div>

        <div className="body">
          <div className="mb-4">
            <label className="form-label">Title</label>
            <input
              className="form-control"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>


          <div className="mb-4">
            <label className="form-label">Cover Photo </label>
            {
              (cover) ?
                <img
                  width="100%"
                  height="auto"
                  src={cover}
                />
                : ""
            }
            <input
              className="form-control"
              type="file"
              accept="image/*"
              id="_cover"
              onChange={(e) => getCover(e)}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">Body</label>
            <Editor
              tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6/tinymce.min.js"
              initialValue="<p></p>"
              onInit={(evt, editor) => {
                setIsEditorReady(true);
              }}
              ref={editorRef}
              init={{
                height: 500,
                menubar: false,
                branding: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                  'preview', 'anchor', 'searchreplace', 'visualblocks',
                  'code', 'fullscreen', 'insertdatetime', 'media', 'table',
                  'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
                  'alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist outdent indent | removeformat | image media | help',
                images_upload_handler: imageUploadHandler,
                file_picker_callback: filePickerCallback,
                image_title: true, // Enable the title field in the Image dialog
                automatic_uploads: true // Automatically upload images dropped or pasted into the editor
              }}
              onEditorChange={handleEditorChange}
            />
          </div>

          {/*<div className="mb-4">
            <label className="form-label">Is Quiz taking mandatory for this course part?</label>
            <select
              className="form-control"
              type="text"
              value={isQuizMandatory}
              onChange={(e) => setIsQuizMandatory(e.target.value)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>*/}

          <div className="mb-4">
            <label className="form-label">Quiz</label>
            {
              quiz.map((item, i) => {
                return (
                  <div key={i} className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h6
                          className="font-semi-bold align-self-center mNoMargin"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          QUESTION #{i + 1}
                        </h6>

                        <MdDelete
                          size={30}
                          className="text-danger"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => deleteQuestion(i)}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-12 mt-4 mb-4">
                          <label className="form-label">Question</label>
                          <textarea
                            value={item.question}
                            onChange={(e) => handleQuestionFieldChange(i, 'question', e.target.value)}
                            className="form-control"
                          ></textarea>
                        </div>

                        <div className="col-md-6 mt-4 mb-4">
                          <label className="form-label">Answer Type</label>
                          <select
                            value={item.answerType}
                            onChange={(e) => handleQuestionFieldChange(i, 'answerType', e.target.value)}
                            className="form-control"
                          >
                            <option value="simple">Simple</option>
                            <option value="range">Range</option>
                          </select>
                        </div>

                        {
                          (item.answerType === 'range') ?
                            <div className="col-md-6 mt-4 mb-4">
                              <label className="form-label">Answer Max</label>
                              <input
                                value={item.answerMax}
                                onChange={(e) => handleQuestionFieldChange(i, 'answerMax', e.target.value)}
                                className="form-control"
                              />
                              <hr />
                              <label className="form-label">Answer Min</label>
                              <input
                                value={item.answerMin}
                                onChange={(e) => handleQuestionFieldChange(i, 'answerMin', e.target.value)}
                                className="form-control"
                              />
                            </div>
                            :
                            <div className="col-md-6 mt-4 mb-4">
                              <label className="form-label">Answer</label>
                              <input
                                value={item.answer}
                                onChange={(e) => handleQuestionFieldChange(i, 'answer', e.target.value)}
                                className="form-control"
                              />
                              <hr />
                              <label className="form-label">Other Wrong Multiple Choices</label>
                              <textarea
                                value={item.multipleChoices}
                                onChange={(e) => handleQuestionFieldChange(i, 'multipleChoices', e.target.value)}
                                className="form-control"
                              ></textarea>
                              <div className="form-text">Separate with commas</div>
                            </div>
                        }
                      </div>

                    </div>
                  </div>
                )
              })
            }
            <div className="text-end">
              <button
                className="btn btn-sm btn-primary"
                onClick={addQuestion}
              >
                Add Question
              </button>
            </div>
          </div>

          <div className="mb-4">
            <button
              className="btn btn-padded bg-secondary btn-block"
              onClick={(data) ? updateArticle : createArticle}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}
