import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { MdOutlineCheckCircle, MdPerson } from "react-icons/md";
import Login from "./Login";
import { STR_APPLY_NOW, STR_CERTIFICATE, STR_DEGREE_EDU, STR_DESCRIPTION, STR_DIPLOMA_EDU, STR_DONT_MATCH_FOR_JOB, STR_HIGH_SCHOOL_EDU, STR_JOB_OPENING, STR_MASTERS_EDU, STR_MIN_EDUCATION_LEVEL, STR_NO_RECORDS, STR_PRIMARY_EDU, STR_REQUIRED_SKILLS, STR_SECONDARY_EDU, STR_SUCCESSFUL, STR_YOU_APPLIED_FOR_THIS_JOB, STR_YOU_MATCH_FOR_THIS_JOB } from "../Strings";
import OneJobCandidate from '../ones/OneJobCandidate';
import { TiWarningOutline } from "react-icons/ti";

export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [jobMatches, setJobMatches] = useState(null);
	const [doesUserMatch, setDoesUserMatch] = useState(false);
	const [hasUserApplied, setHasUserApplied] = useState(false);
	const [loading, setLoading] = useState(false);


	async function init() {
		//run initializations here
		setReady(false);
		await Promise.all([
			getJobMatches(),
			appContext.getMyJobApplications(),
		]);
		setReady(true);
	}

	async function getJobMatches() {
		await callApi("get-job-matches", { id }).then(response => {
			if (response.status === 1) {
				setJobMatches(response.data);
			}
		})
	}

	async function applyNow() {
		setLoading(true);
		await callApi("apply-for-job", { id, applicantId: appContext.currentUserData.id }).then(response => {
			if (response.status === 1) {
				appContext.getMyJobApplications();
				appContext.tellMessage(STR_SUCCESSFUL[appContext.language]);
			} else {
				appContext.tellError(response.msg);
			}
		});
		setLoading(false);
	}

	function checkMatch() {
		if(jobMatches) {
			for (const _match of jobMatches) {
				if(Number(_match.id) === Number(appContext.currentUserData.id)) {
					setDoesUserMatch(true);
				}
			}
		}
	}

	function checkIfApplied() {
		
		if(appContext.myJobApplications) {
			for (const _application of appContext.myJobApplications) {

				if(Number(_application.jobId) === Number(id)) {
					setHasUserApplied(true);
					console.log("User applied");
					
				}
			}
		}
	}

	useEffect(() => {
		if(jobMatches) {
			checkMatch();
		}
	}, [ jobMatches ])

	useEffect(() => {
		if(appContext.myJobApplications) {
			checkIfApplied();
		}
	}, [ appContext.myJobApplications ])

	useEffect(() => {
		init();
	}, [id])

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);

		return () => {
			appContext.setShowOverlayLoader(false);
		}
	}, [ loading ])

	if (ready && jobMatches) {
		
		return (
			<div className="container text-center" style={{  }}>
				{
					(doesUserMatch) ?
						(hasUserApplied) ?
						<h6
							style={{
								fontSize: "24px"
							}}
						>
							<MdOutlineCheckCircle size={62}/> <br/>
							{STR_YOU_APPLIED_FOR_THIS_JOB[appContext.language]}
						</h6>
						:
						<div>
							<h6
								className="mt-4"
								style={{
									fontSize: "24px"
								}}
							>
								{STR_YOU_MATCH_FOR_THIS_JOB[appContext.language]}

								<br/>

								<button
									className="btn btn-padded btn-rounded btn-primary mt-4 mb-4"
									onClick={() => {
										appContext.activateDialog({
											message: STR_APPLY_NOW[appContext.language],
											onConfirm: applyNow,
										})
									}}
								>
									{STR_APPLY_NOW[appContext.language]}
								</button>
							</h6>
						</div>
					:
					<h6
						style={{
							fontSize: "24px"
						}}
					>
						<TiWarningOutline size={62}/> <br/>
						{STR_DONT_MATCH_FOR_JOB[appContext.language]}
					</h6>
				}
			</div>
		)

	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}



}